import { Box, Loader, Space } from "design-system";
import { IO } from "fp-ts/IO";
import { useMemo } from "react";
import { checkPush } from "../../SmartKey/api";
import { usePollingEffect } from "../../useAPI";
import { Path } from "../../APICall";

type Props = {
  onSuccess: IO<unknown>;
  onFailure: IO<unknown>;
  onCancel: IO<unknown>;
  checkAuthorizationPushPath: Path;
};

export function SBAuthorizationResultWaiting(props: Props) {
  const checkAuthorization = useMemo(
    () => checkPush(props.checkAuthorizationPushPath),
    []
  );

  usePollingEffect(checkAuthorization, {
    intervalMS: 3000,
    shouldPollingContinue: ({ status }) => status === "RETRY",
    onError: props.onFailure,
    onSuccess: ({ status }) => {
      switch (status) {
        case "RETRY":
          return;
        case "ERR":
          return props.onFailure();
        case "OK":
          return props.onSuccess();
        case "CANCEL":
          return props.onCancel();
      }
    },
  });

  return (
    <Box column hAlignContent="center">
      <Space units={10} />
      <Loader />
    </Box>
  );
}
