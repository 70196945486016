import { LocalizedStringBrand } from "design-system";
import { option } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { Option } from "fp-ts/Option";
import { MainLayout } from "../MainLayout/MainLayout";
import { PortalStatusAlert } from "../PortalStatusAlert/PortalStatusAlert";
import { ValidateDetailsOutput } from "./api";
import { Branded } from "io-ts";
import { UserNotValidatedError } from "./UserNotValidatedError";
import { headerActions, MainContent } from "../MainContent";

type Props = {
  validationResult: ValidateDetailsOutput;
  onExit: Option<IO<unknown>>;
  onDismiss: Option<IO<unknown>>;
  title: Branded<string, LocalizedStringBrand>;
};

export function UserNotValidatedDialog(props: Props) {
  return (
    <MainLayout
      steps={option.none}
      backgroundImageURL={option.none}
      onLogoClick={props.onExit}
      onStepClick={constVoid}
      iconAction={pipe(
        props.onExit,
        option.map(onExit => headerActions.exit(onExit))
      )}
      title={option.some(props.title)}
    >
      <PortalStatusAlert inAuthenticatedPage />
      <MainContent>
        <UserNotValidatedError {...props} />
      </MainContent>
    </MainLayout>
  );
}
