import * as t from "io-ts";

export const SearchStatusType = t.keyof({
  PENDING: true,
  CLOSED: true,
});

export type SearchStatusType = t.TypeOf<typeof SearchStatusType>;

export const SearchSourceType = t.keyof({
  ALL: true,
  YOU: true,
});

export type SearchSourceType = t.TypeOf<typeof SearchSourceType>;
