import * as t from "io-ts";
import { eq, option, array } from "fp-ts";
import { NonNegativeInteger, PositiveInteger } from "design-system";
import { apiCall } from "../../APICall";
import { withFallback } from "io-ts-types/lib/withFallback";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DateFromISOString } from "io-ts-types/lib/DateFromISOString";
import { Currency, optionFromUndefined } from "../../globalDomain";
import { SearchSourceType, SearchStatusType } from "./domain";
import { constFalse } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { Reader } from "fp-ts/Reader";

export const ApplicationProductType = t.keyof({
  CL: true,
  UKONTO: true,
  SAVINGS: true,
  MORTGAGE: true,
  PERSONAL_PROFILE: true,
  SL: true,
  TL: true,
  RL: true,
  RPL: true,
  OD: true,
});

export type ApplicationProductType = t.TypeOf<typeof ApplicationProductType>;

const ApplicationLoanType = optionFromNullable(t.literal("SL"));

export type ApplicationLoanType = t.TypeOf<typeof ApplicationLoanType>;

export const UserType = t.keyof({
  BROKER: true,
  BANKER: true,
  FRANCHISE_PARTNER: true,
  FRANCHISE_AS_BRANCH: true,
  CLIENT: true,
  UNKNOWN: true,
});

export type UserType = t.TypeOf<typeof UserType>;

const FlowType = t.keyof({
  TLS: true,
  IN_PERSON: true,
  REMOTE: true,
});
type FlowType = t.TypeOf<typeof FlowType>;

export function foldApplicationRecordFlowType<T>(
  matches: {
    [k in FlowType]: IO<T>;
  }
): Reader<FlowType, T> {
  return flowType => matches[flowType]();
}

export const ApplicationStatus = t.keyof(
  {
    BLOCKED_FOR_REMOTE_EMAIL_LINK: true,
    SENT_TO_THE_BANK: true,
    PRELIMINARY_APPROVED: true,
    TO_BE_REVIEWED_INDIVIDUALLY: true,
    PRELIMINARY_DECLINED: true,
    CREATED: true,
    PENDING_SIGNATURE: true,
    DELETED: true,
    EXPIRED: true,
    SIGNED: true,
    HARD_KO: true,
    AC_SENT_TO_THE_BANK: true,
    AC_PRELIMINARY_APPROVED: true,
    AC_TO_BE_REVIEWED_INDIVIDUALLY: true,
    AC_PRELIMINARY_DECLINED: true,
    ADDITIONAL_INFO_REQUIRED: true,
    APPLICATION_RENOUNCED: true,
    FINAL_DECISION_ACCEPTED: true,
    FINAL_DECISION_REJECTED: true,
    APPROVED: true,
    REJECTED: true,
    SIGNED_DOCUMENTS_NEEDED: true,
    UNDER_REWORK: true,
    IN_PROGRESS: true,
    AC_IN_PROGRESS: true,
  },
  "ApplicationStatus"
);

export type ApplicationStatus = t.TypeOf<typeof ApplicationStatus>;

export const SLApplicationStatusResumable = t.intersection([
  t.keyof({
    CREATED: true,
    PENDING_SIGNATURE: true,
    SIGNED_DOCUMENTS_NEEDED: true,
    UNDER_REWORK: true,
    IN_PROGRESS: true,
  }),
  ApplicationStatus,
]);

export type SLApplicationStatusResumable = t.TypeOf<
  typeof SLApplicationStatusResumable
>;

export const SLApplicationStatusFinished = t.intersection([
  t.keyof({
    APPROVED: true,
    REJECTED: true,
  }),
  ApplicationStatus,
]);

const TenorType = t.keyof({
  Months: true,
  Years: true,
});

export const ApplicationRecord = t.type(
  {
    brokerId: NonEmptyString,
    applicationId: NonEmptyString,
    firstName: withFallback(optionFromNullable(NonEmptyString), option.none),
    lastName: withFallback(optionFromNullable(NonEmptyString), option.none),
    createdDate: DateFromISOString,
    lastModifiedDate: DateFromISOString,
    expirationDate: optionFromUndefined(DateFromISOString),
    productType: ApplicationProductType,
    loanType: ApplicationLoanType,
    amount: optionFromUndefined(NonNegativeInteger),
    currency: optionFromUndefined(Currency),
    tenor: optionFromUndefined(PositiveInteger),
    tenorType: optionFromUndefined(TenorType),
    status: ApplicationStatus,
    flowType: FlowType,
    userType: UserType,
    userName: withFallback(optionFromNullable(NonEmptyString), option.none),
    locked: t.boolean,
    expiresSoon: withFallback(t.boolean, false),
    clientPhoneNumber: withFallback(
      optionFromNullable(NonEmptyString),
      option.none
    ),
  },
  "ApplicationListRecord"
);

const BankerApplicationListInput = t.type(
  {
    clientNameOrApplicationId: t.string,
    size: PositiveInteger,
    status: SearchStatusType,
    birthNumber: t.string,
    clientNumber: t.string,
    accountNumber: t.string,
    emailAddress: t.string,
    clientUserIdOrAlias: t.string,
    phoneNumber: t.string,
    productTypeList: t.array(ApplicationProductType),
    fromDate: optionFromNullable(DateFromISOString),
    toDate: optionFromNullable(DateFromISOString),
    source: SearchSourceType,
  },
  "BankerApplicationListInput"
);

const eqBankerApplicationListInput = eq.getStructEq({
  size: eq.eqNumber,
  clientNameOrApplicationId: eq.eqString,
  status: eq.eqStrict,
  birthNumber: eq.eqString,
  clientNumber: eq.eqString,
  accountNumber: eq.eqString,
  emailAddress: eq.eqString,
  clientUserIdOrAlias: eq.eqString,
  phoneNumber: eq.eqString,
  productTypeList: array.getEq(eq.eqStrict),
  fromDate: option.getEq(eq.eqDate),
  toDate: option.getEq(eq.eqDate),
  source: eq.eqStrict,
});

const BankerApplicationListOutput = t.array(
  ApplicationRecord,
  "BankerApplicationListOutput"
);

export type BankerApplicationListOutput = t.TypeOf<
  typeof BankerApplicationListOutput
>;

export type ApplicationRecord = t.TypeOf<typeof ApplicationRecord>;

export const getBankerApplicationList = apiCall({
  inputCodec: BankerApplicationListInput,
  inputEq: eqBankerApplicationListInput,
  path: ["utilities", "process", "store", "bybankerid"],
  outputCodec: BankerApplicationListOutput,
});

const BrokerApplicationListInput = t.type(
  {
    searchText: t.string,
    page: NonNegativeInteger,
    size: PositiveInteger,
  },
  "BrokerApplicationListInput"
);

const eqBrokerApplicationListInput = eq.getStructEq({
  searchText: eq.eqString,
  page: eq.eqNumber,
  size: eq.eqNumber,
});

const BrokerApplicationListOutput = t.type(
  {
    content: t.array(ApplicationRecord),
    totalPages: NonNegativeInteger,
    totalElements: NonNegativeInteger,
    last: t.boolean,
    size: NonNegativeInteger,
    number: NonNegativeInteger,
  },
  "BrokerApplicationListOutput"
);

export const getBrokerApplicationList = apiCall({
  inputCodec: BrokerApplicationListInput,
  inputEq: eqBrokerApplicationListInput,
  path: ["utilities", "process", "store", "bybrokerid"],
  outputCodec: BrokerApplicationListOutput,
});

const GetClientApplicationsListOutput = t.array(ApplicationRecord);
export type GetClientApplicationsListOutput = t.TypeOf<
  typeof GetClientApplicationsListOutput
>;

export const getClientApplicationsList = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  path: ["utilities", "process", "store", "getApplicationsForClient"],
  outputCodec: GetClientApplicationsListOutput,
});
