import { AlertDialog, WarningIcon } from "design-system";
import { useFormatMessage } from "../../intl";

type Props = {
  onAnswer: (answer: boolean) => unknown;
  onDismiss: () => unknown;
};

export function MarketingConsentConfirmationDialog(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <AlertDialog
      type="confirmation"
      title={formatMessage("UKonto.ReadDocuments.MarketingConsentModal.title")}
      icon={WarningIcon}
      message={formatMessage(
        "UKonto.ReadDocuments.MarketingConsentModal.description"
      )}
      confirmLabel={formatMessage(
        "UKonto.ReadDocuments.MarketingConsentModal.confirmLabel"
      )}
      cancelLabel={formatMessage(
        "UKonto.ReadDocuments.MarketingConsentModal.cancelLabel"
      )}
      onConfirm={() => props.onAnswer(true)}
      onCancel={() => props.onAnswer(false)}
      onDismiss={props.onDismiss}
    />
  );
}
