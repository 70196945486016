import {
  Box,
  Space,
  Banner,
  useAuthenticationContext,
  LocalizedString,
} from "design-system";

import { option } from "fp-ts";
import { constNull } from "fp-ts/function";
import { foldPortalStatus } from "../../globalDomain";
import { usePortalStatusContext } from "../../PortalStatusContext";
import { PortalStatusAlertDialog } from "./PortalStatusAlertDialog";

type Props = { inAuthenticatedPage: boolean };

export function PortalStatusAlert(props: Props) {
  const { logout } = useAuthenticationContext();

  const {
    portalStatus,
    isAlertModalVisible,
    setAlertModalVisible,
  } = usePortalStatusContext();

  return (
    <>
      {foldPortalStatus(portalStatus, {
        onInitial: constNull,
        onNone: constNull,
        onActive: (message: LocalizedString) => (
          <Box column grow>
            <Space units={5} />
            <Banner
              type="error"
              content={message}
              actions={option.none}
              title={option.none}
            />
            {props.inAuthenticatedPage && isAlertModalVisible && (
              <PortalStatusAlertDialog
                message={message}
                onCancel={() => setAlertModalVisible(false)}
                onConfirm={logout}
                onDismiss={() => setAlertModalVisible(false)}
              />
            )}
          </Box>
        ),
        onScheduled: (message: LocalizedString) => (
          <Box column grow>
            <Space units={5} />
            <Banner
              type="warning"
              content={message}
              actions={option.none}
              onDismiss={option.none}
              title={option.none}
            />
          </Box>
        ),
      })}
    </>
  );
}
