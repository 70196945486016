import {
  validateDetails as validateDetailsCall,
  ValidateDetailsOutput,
} from "./api";
import { constVoid, pipe } from "fp-ts/function";
import { boolean } from "fp-ts";
import { UserNotValidatedDialog } from "./UserNotValidatedDialog";
import * as option from "fp-ts/Option";
import { Option } from "fp-ts/Option";
import * as t from "io-ts";
import { Branded } from "io-ts";
import {
  Action,
  Box,
  FeedbackBlock,
  FeedbackDialog,
  LocalizedStringBrand,
} from "design-system";
import { Reader } from "fp-ts/Reader";
import { UserNotValidatedError } from "./UserNotValidatedError";
import { useState } from "react";
import { ContactChange } from "../../ClientProfile/ContactChange";
import { useAppContext } from "../../useAppContext";
import { useFormatMessage } from "../../intl";
import { useQuery, usePollingEffect } from "../../useAPI";
import * as remoteData from "../../RemoteData";
import * as api from "./api";

type Props = {
  validateUser: Reader<ValidateDetailsOutput, boolean>;
  onDialogExit: Option<() => unknown>;
  onDialogDismiss: Option<() => unknown>;
  dialogTitle: Branded<string, LocalizedStringBrand>;
  children: JSX.Element | null;
  isModal: boolean;
  skipValidation?: boolean;
};

const AddPhoneState = t.keyof(
  {
    Initial: true,
    InProgress: true,
    Completed: true,
  },
  "AddPhoneState"
);
type AddPhoneState = t.TypeOf<typeof AddPhoneState>;

const ValidationState = t.keyof(
  {
    AddPhone: true,
    Validation: true,
  },
  "ValidationState"
);
type ValidationState = t.TypeOf<typeof ValidationState>;

export function UserValidation(props: Props) {
  const {
    apiParameters: { channel },
  } = useAppContext();
  const formatMessage = useFormatMessage();
  const [userValidation, refreshUserDetails] = useQuery(validateDetailsCall);

  const [addPhoneState, setAddPhoneState] = useState<AddPhoneState>("Initial");
  const [validationState, setValidationState] = useState<ValidationState>(
    "Validation"
  );
  const canAddPhoneIfMissing =
    channel === "TLS_Remote" ||
    channel === "OB_Remote" ||
    channel === "SB_Remote" ||
    channel === "PWS_Remote";

  const [poolingDisabled, setPoolingDisabled] = useState(true);
  const [updatePhoneCompleted, setUpdatePhoneCompleted] = useState(false);

  usePollingEffect(api.results, {
    intervalMS: 3000,
    shouldPollingContinue: result => result.status === "PENDING",
    disabled: poolingDisabled,
    onError: constVoid,
    onSuccess: result => {
      if (result.status === "DONE") {
        setUpdatePhoneCompleted(true);
      }
    },
  });

  const validate = pipe(
    userValidation,
    remoteData.fold(
      () => (
        <Box height="100vh" hAlignContent="center" vAlignContent="center">
          <FeedbackBlock
            size="large"
            subheading={option.none}
            type="loading"
            heading={formatMessage("UserValidation.loading.title")}
          />
        </Box>
      ),
      () => (
        <FeedbackDialog
          type="negative"
          title={formatMessage("UserValidation.genericError.title")}
          subtitle={formatMessage("UserValidation.genericError.message")}
          cta={{
            label: formatMessage("Ok"),
            action: pipe(
              props.onDialogExit,
              option.getOrElse(() => constVoid)
            ),
          }}
        />
      ),

      result =>
        pipe(
          props.validateUser(result),
          boolean.fold(
            () => {
              if (!result.hasPhoneNumber && canAddPhoneIfMissing) {
                if (updatePhoneCompleted) {
                  return props.children;
                }
                if (validationState !== "AddPhone") {
                  setPoolingDisabled(false);
                  setValidationState("AddPhone");
                }
                return null;
              }

              return pipe(
                props.isModal,
                boolean.fold(
                  () => (
                    <UserNotValidatedError
                      title={props.dialogTitle}
                      onDismiss={props.onDialogDismiss}
                      validationResult={result}
                    />
                  ),
                  () => (
                    <UserNotValidatedDialog
                      title={props.dialogTitle}
                      onExit={props.onDialogExit}
                      onDismiss={props.onDialogDismiss}
                      validationResult={result}
                    />
                  )
                )
              );
            },
            () => props.children
          )
        )
    )
  );

  const foldValidationState = () => {
    switch (validationState) {
      case "Validation":
        return validate;
      case "AddPhone":
        return foldPhoneState();
    }
  };

  const foldPhoneState = () => {
    switch (addPhoneState) {
      case "Completed":
        return (
          <FeedbackBlock
            size="medium"
            type="info"
            heading={
              updatePhoneCompleted
                ? formatMessage("UserValidation.phoneNumberUpdated.title")
                : formatMessage("DARWaitingModal.wait")
            }
            subheading={option.some(
              formatMessage("UserValidation.phoneNumberUpdated.message")
            )}
            actions={
              [
                {
                  variant: "primary",
                  label: formatMessage("UserValidation.button.continueProcess"),
                  disabled: !updatePhoneCompleted,
                  action: () => {
                    setValidationState("Validation");
                    setAddPhoneState("Initial");
                  },
                },
              ] as [Action]
            }
          />
        );
      case "InProgress":
        return (
          <AddPhoneNumber
            onComplete={() => {
              refreshUserDetails();
              setAddPhoneState("Completed");
            }}
            onExit={() => setAddPhoneState("Initial")}
          />
        );
      case "Initial":
        return (
          <FeedbackBlock
            size="medium"
            type="info"
            heading={formatMessage("UserValidation.missingPhoneNumberTitle")}
            subheading={option.some(
              formatMessage("UserValidation.missingPhoneNumberMessage")
            )}
            actions={
              [
                {
                  variant: "primary",
                  label: formatMessage("UserValidation.button.addPhoneNumber"),
                  action: () => setAddPhoneState("InProgress"),
                },
              ] as [Action]
            }
          />
        );
    }
  };

  return props.skipValidation ? props.children : foldValidationState();
}

type AddPhoneProps = {
  onComplete: () => unknown;
  onExit: () => unknown;
};

function AddPhoneNumber(props: AddPhoneProps) {
  return (
    <ContactChange
      data={{
        contactType: "phoneNumber",
        operationType: "edit",
        value: option.none,
        process: option.none,
      }}
      onComplete={props.onComplete}
      onExit={props.onExit}
    />
  );
}
