import { useAppContext } from "../useAppContext";

export function useTestEnvironment() {
  const {
    config: { environment },
  } = useAppContext();

  return (
    environment === "sit" ||
    environment === "uat" ||
    environment === "hf" ||
    environment === "dev"
  );
}
