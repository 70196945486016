import { useAppContext } from "../useAppContext";
import { Currency } from "../globalDomain";

export function useTenantCurrency(): Currency {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  switch (tenant) {
    case "CZ":
      return "CZK";
    case "SK":
      return "EUR";
  }
}
