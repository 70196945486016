import { Panel, Stack, LocalizedString } from "design-system";
import { pipe, constTrue } from "fp-ts/function";
import { option, set, array, eq } from "fp-ts";
import { PreContractualDocumentMeta } from "../documentAPI";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DocumentItem, DocumentReadingConfirmProps } from "./DocumentItem";
import { getDocumentReadingStatus, getFirstUnreadDocument } from "./utils";
import { TaskEither } from "fp-ts/TaskEither";

export type ReadingConfirmProps =
  | {
      readingConfirmationType: "none";
    }
  | {
      readingConfirmationType: "status";
      documentsRead: Set<NonEmptyString>;
    }
  | {
      readingConfirmationType: "checkbox";
      documentsRead: Set<NonEmptyString>;
      onDocumentRead: (documentId: NonEmptyString) => TaskEither<unknown, void>;
      onDocumentUnread: (
        documentId: NonEmptyString
      ) => TaskEither<unknown, void>;
    };

type Props = {
  documents: NonEmptyArray<PreContractualDocumentMeta>;
  documentToCheckForReadingConfirmation?:
    | NonEmptyArray<PreContractualDocumentMeta>
    | undefined;
  showErrors: boolean;
  isSigningStarted?: boolean;
  isForeignClient?: boolean;
  onDocumentOpen: (document: PreContractualDocumentMeta) => unknown;
  onForeignDocumentOpen: (document: PreContractualDocumentMeta) => unknown;
  title: LocalizedString;
} & ReadingConfirmProps;

export function ContractsPanel(props: Props) {
  function getDocumentReadingConfirmProps(
    docId: NonEmptyString
  ): DocumentReadingConfirmProps {
    switch (props.readingConfirmationType) {
      case "none":
        return { readingConfirmationType: "none" };
      case "status":
        return {
          readingConfirmationType: "status",
          readStatus: getDocumentReadingStatus(
            props.documents,
            props.documentsRead
          )(docId),
        };
      case "checkbox":
        const isRead = pipe(props.documentsRead, set.elem(eq.eqStrict)(docId));
        return {
          readingConfirmationType: "checkbox",
          isRead,
          onRead: props.onDocumentRead(docId),
          onUnread: props.onDocumentUnread(docId),
          disabled:
            !isRead &&
            pipe(
              getFirstUnreadDocument(
                props.documentToCheckForReadingConfirmation
                  ? props.documentToCheckForReadingConfirmation
                  : props.documents,
                props.documentsRead
              ),
              option.fold(constTrue, d => d.docId !== docId)
            ),
        };
    }
  }

  return (
    <Panel
      headerIcon={option.none}
      isOpen={true}
      title={props.title}
      status={props.isSigningStarted ? "disabled" : "active"}
      onSelect={option.none}
      content={() => (
        <Stack column units={16} divider>
          {pipe(
            props.documents,
            array.mapWithIndex((index, document) => (
              <DocumentItem
                document={document}
                dataTestId={`contract_${index + 1}`}
                key={document.docId}
                showErrors={props.showErrors}
                {...getDocumentReadingConfirmProps(document.docId)}
                onDocumentOpen={props.onDocumentOpen}
                onForeignDocumentOpen={props.onForeignDocumentOpen}
                isForeignClient={props.isForeignClient}
              />
            ))
          )}
        </Stack>
      )}
    />
  );
}
