import { FieldProps, LocalizedString, RadioGroupField } from "design-system";
import { Option } from "fp-ts/Option";
import { constant, constFalse } from "fp-ts/function";
import { boolean, option } from "fp-ts";
import { useFormatMessage } from "../../intl";

type Props = FieldProps<Option<boolean>> & {
  id?: string;
  value: Option<boolean>;
  onChange: (value: Option<boolean>) => unknown;
  description?: LocalizedString;
  cta?: {
    label: LocalizedString;
    action: () => unknown;
  };
};

export function YesNoRadioGroupField(props: Props) {
  const formatMessage = useFormatMessage();

  const handleOnChange = (value: option.Option<boolean>) => {
    props.onBlur();
    props.onChange(value);
  };

  return (
    <RadioGroupField
      {...props}
      onChange={handleOnChange}
      variant="horizontal"
      options={[true, false]}
      optionKey={value => value.toString()}
      renderOption={boolean.fold(
        () => formatMessage("No"),
        () => formatMessage("Yes")
      )}
      renderOptionChildren={constant(option.none)}
      isOptionDisabled={constFalse}
    />
  );
}
