import { usePolling } from "../../useAPI";
import * as api from "../../StandardLoan/api";
import { OpenLoanApplicationWrapperLatest } from "./OpenLoanApplicationWrapperLatest";
import StandardLoan from "../../StandardLoan/StandardLoan";
import { option } from "fp-ts";
import { constNull, constVoid, pipe } from "fp-ts/function";
import * as remoteData from "../../RemoteData";
import { BasicFullScreenLoader } from "../BasicFullScreenLoader";
import { foldClientStatus } from "../../StandardLoan/FollowUpAndSignature/LowRiskClient/LowRiskClientState";
import { CheckingContractsLoader } from "../../StandardLoan/FollowUpAndSignature/LowRiskClient/CheckingContractsLoader";
import { useAppContext } from "../../useAppContext";

export const OpenLoanApplicationForRemoteSigning = () => {
  const {
    config: { PWSURL },
  } = useAppContext();

  const navigatePWS = () => {
    window.location.href = PWSURL;
  };

  const [getClientStatusResult] = usePolling(api.getClientStatus, {
    shouldPollingContinue: ({ status }) => status === "PENDING",
    intervalMS: 3000,
  });

  const renderStandardLoan = (
    <OpenLoanApplicationWrapperLatest
      renderStandardLoan={restoreDataObject => (
        <StandardLoan
          restoreDataObject={restoreDataObject}
          onPhoneVerified={option.none}
          onExit={option.some(navigatePWS)}
          onClientExists={constVoid}
          flowType="InPerson"
          authenticationMethod={option.some("ID")}
          initialLoanOffer={option.none}
          onAgeValidationFail={option.none}
          applicationStatus={option.none}
          isProspectClient={true}
          isCredentialsAndSigningRemote={true}
          clientExists={false}
        />
      )}
    />
  );

  return pipe(
    getClientStatusResult,
    remoteData.fold(
      () => <BasicFullScreenLoader />,
      constNull,
      response =>
        pipe(
          response,
          foldClientStatus(
            () => <BasicFullScreenLoader />,
            () => (
              <CheckingContractsLoader
                status="error"
                flowType="InPerson"
                isRiskyClient={false}
              />
            ),
            () => renderStandardLoan
          )
        )
    )
  );
};
