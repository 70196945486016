import { pipe, constNull } from "fp-ts/function";
import { option } from "fp-ts";
import { getLastSavedLoan } from "../../StandardLoan/api";
import { Option } from "fp-ts/Option";

import { RestoreDataObjectSL } from "./domain";

import * as remoteData from "../../RemoteData";
import { useQuery } from "../../useAPI";

export const OpenLoanApplicationWrapperLatest = (props: {
  renderStandardLoan: (restoreData: Option<RestoreDataObjectSL>) => JSX.Element;
}) => {
  const [openLoan] = useQuery(getLastSavedLoan);

  return pipe(
    openLoan,
    remoteData.fold(
      constNull,
      () => props.renderStandardLoan(option.none),
      response =>
        props.renderStandardLoan(
          option.some({
            restoreData: response.restoreData,
            restoreDataType: "latest",
          })
        )
    )
  );
};
