import { useAuthenticationContext } from "design-system";
import { option } from "fp-ts";
import { useAppContext } from "../useAppContext";

export type DeepLink = "PAL-authorize" | "PAL-exit" | "CP-authorize";

export function useSBDeepLink(link: DeepLink) {
  const { authInfo } = useAuthenticationContext();

  const url = (() => {
    switch (link) {
      case "PAL-authorize":
      case "CP-authorize":
        if (option.isNone(authInfo)) {
          throw new Error(
            "Trying to call native app for authorization without a flow ID"
          );
        }
        const flowId = authInfo.value.flowId;
        const biometric = link === "CP-authorize" ? "true" : "false";

        return `mb-unicredit://sign/query?section=DBN&reference=${flowId}&biometric=${biometric}`;
      case "PAL-exit":
        return "mb-unicredit://close/query?appSection=online:menu:more";
    }
  })();

  return () => {
    window.location.href = url;
    return "";
  };
}

export function useAppRedirect() {
  const {
    apiParameters: { tenant },
    config: { environment },
  } = useAppContext();

  const redirectUrl =
    (() => {
      switch (environment) {
        case "sit":
        case "uat":
          return ["mb", "unicredit", tenant, environment].join("-");
        case "dev":
        case "ci":
        case "hf":
        case "prod":
          return ["mb", "unicredit", tenant].join("-");
      }
    })() + "://appredirect";

  return () => (window.location.href = redirectUrl);
}
