import { ComponentProps } from "react";
import { Box, ReadOnlyField, TextField } from "design-system";
import * as classes from "./OptionalEditTextField.treat";

type Feedback = ComponentProps<typeof ReadOnlyField>["feedback"];

type Props = ComponentProps<typeof TextField> & {
  edit: boolean;
  feedback?: Feedback;
};

export function OptionalEditTextField(props: Props) {
  const { edit, ...fieldProps } = props;

  if (edit) return <TextField {...fieldProps} />;

  if (props.value.length === 0) return null;

  return (
    <Box className={classes.textAlignment}>
      <ReadOnlyField
        size="medium"
        label={props.label}
        value={props.value}
        feedback={props.feedback}
      />
    </Box>
  );
}
