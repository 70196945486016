import { pipe } from "fp-ts/function";
import { TextChildren, LocalizedString, ReadOnlyField } from "design-system";
import { boolean } from "fp-ts";
import { useFormatMessage } from "../../intl";
import { ComponentProps } from "react";

type Props = {
  id?: string;
  size: ComponentProps<typeof ReadOnlyField>["size"];
  value: boolean;
  label: TextChildren;
  description?: LocalizedString;
};

export function ReadOnlyYesNoField(props: Props) {
  const formatMessage = useFormatMessage();
  return (
    <ReadOnlyField
      {...props}
      value={pipe(
        props.value,
        boolean.fold(
          () => formatMessage("No"),
          () => formatMessage("Yes")
        )
      )}
    />
  );
}
