import {
  useIsTouchScreen,
  SnackBarProvider as DSSnackbarProvider,
  SnackbarState,
  Children,
  LocalizedString,
} from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { ComponentProps, useState } from "react";
import {
  useChildSharedReducer,
  useParentSharedReducer,
} from "../../BranchExperience/useSharedReducer";
import {
  reducerConfig,
  setStateAction,
  State,
  Action,
} from "./branchExperienceState";

type Props = {
  children: Children;
};

type LocalStateProps = {
  getState: (
    sharedState: State
  ) => ComponentProps<typeof DSSnackbarProvider>["state"];
  getSetState: (
    dispatch: (action: Action) => unknown
  ) => ComponentProps<typeof DSSnackbarProvider>["setState"];
};

function SnackbarProviderParent(props: Props & LocalStateProps) {
  const [sharedSnackbar, dispatch] = useParentSharedReducer(
    reducerConfig,
    option.none
  );
  return (
    <DSSnackbarProvider
      children={props.children}
      state={props.getState(sharedSnackbar)}
      setState={props.getSetState(dispatch)}
    />
  );
}

function SnackbarProviderChild(props: Props & LocalStateProps) {
  const [_sharedSnackbar, dispatch] = useChildSharedReducer(reducerConfig);
  const sharedSnackbar = pipe(_sharedSnackbar, option.flatten);
  return (
    <DSSnackbarProvider
      children={props.children}
      state={props.getState(sharedSnackbar)}
      setState={props.getSetState(dispatch)}
    />
  );
}

export function SnackbarProvider(props: Props) {
  const isTouchScreen = useIsTouchScreen();
  const Component = isTouchScreen
    ? SnackbarProviderChild
    : SnackbarProviderParent;
  const [snackbar, setSnackbar] = useState<Option<SnackbarState>>(option.none);
  const getState = (sharedSnackbar: State) =>
    pipe(
      snackbar,
      option.alt(() =>
        pipe(
          sharedSnackbar,
          option.filter(s => {
            switch (s.targetScreen) {
              case "child":
                return isTouchScreen;
              case "parent":
                return !isTouchScreen;
              case "both":
                return true;
            }
          }),
          option.map(
            (s): SnackbarState => ({
              type: s.type,
              message: s.message,
              action: option.none,
            })
          )
        )
      )
    );
  const getSetState = (dispatch: (action: Action) => unknown) => (
    newSnackbar: Option<SnackbarState>
  ) => {
    setSnackbar(
      pipe(
        newSnackbar,
        option.filter(
          s => s.targetScreen === "self" || s.targetScreen === "both"
        )
      )
    );
    dispatch(
      setStateAction(
        pipe(
          newSnackbar,
          option.chain(s =>
            LocalizedString.is(s.message)
              ? option.some({
                  type: s.type,
                  message: s.message,
                  targetScreen: (() => {
                    switch (s.targetScreen || "self") {
                      case "self":
                        return isTouchScreen ? "child" : "parent";
                      case "other":
                        return isTouchScreen ? "parent" : "child";
                      case "both":
                        return "both";
                    }
                  })(),
                })
              : option.none
          )
        )
      )
    );
  };
  return <Component {...props} getState={getState} getSetState={getSetState} />;
}
