import { LocalizedString } from "design-system";
import { array, eq, option, set, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { TaskEither } from "fp-ts//TaskEither";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { Dispatch } from "react";
import { useFormatMessage } from "../../intl";
import { PreContractualDocumentMeta } from "../documentAPI";
import {
  UIError,
  Action,
  unreadDocumentAction,
  readDocumentAction,
  changeMarketingConsentAction,
  changeDocumentsReceivedConfirmationAction,
  confirmMarketingConsentAction,
  changeForeignSigningConsentAction,
} from "./state";
import { ExistingClientAuthenticationMethod } from "../../globalDomain";
import { SmartKeyMode } from "../../SmartKey/SmartKeyState";

export type DocumentReadStatus = "Unread" | "Reading" | "Read";

export function getFirstUnreadDocument(
  documents: NonEmptyArray<PreContractualDocumentMeta>,
  documentsRead: Set<NonEmptyString>
): Option<PreContractualDocumentMeta> {
  return pipe(
    documents,
    array.findFirst(d =>
      pipe(
        documentsRead,
        set.every(d2 => d2 !== d.docId)
      )
    )
  );
}

export function getDocumentReadingStatus(
  documents: NonEmptyArray<PreContractualDocumentMeta>,
  documentsRead: Set<NonEmptyString>
) {
  return (docId: NonEmptyString): DocumentReadStatus => {
    const isRead = pipe(documentsRead, set.elem(eq.eqStrict)(docId));

    if (isRead) {
      return "Read";
    } else {
      if (
        pipe(
          getFirstUnreadDocument(documents, documentsRead),
          option.exists(d => d.docId === docId)
        )
      ) {
        return "Reading";
      }
      return "Unread";
    }
  };
}

export const useFormatError = () => {
  const formatMessage = useFormatMessage();
  return (error: UIError): LocalizedString => {
    switch (error) {
      case "DocumentsUnread":
        return formatMessage("UKonto.ReadDocuments.unreadDocumentsError");
    }
  };
};

export const getPreContractualDocumentsActions = (
  dispatch: Dispatch<Action>,
  commands: {
    contractRead: ReaderTaskEither<NonEmptyString, unknown, unknown>;
    contractUnread: ReaderTaskEither<NonEmptyString, unknown, unknown>;
    marketingConsent: ReaderTaskEither<boolean, unknown, unknown>;
    termsAndConditionsReceived: TaskEither<unknown, unknown>;
    foreignSigningConsent?: ReaderTaskEither<boolean, unknown, unknown>;
  }
) => {
  return {
    handleContractRead: (docId: NonEmptyString) =>
      pipe(
        commands.contractRead(docId),
        taskEither.map(() => dispatch(readDocumentAction(docId)))
      ),
    handleContractUnread: (docId: NonEmptyString) =>
      pipe(
        commands.contractUnread(docId),
        taskEither.map(() => dispatch(unreadDocumentAction(docId)))
      ),
    handleMarketingConsentAcceptance: (accepted: boolean) =>
      pipe(
        commands.marketingConsent(accepted),
        taskEither.map(() => dispatch(changeMarketingConsentAction(accepted)))
      ),
    handlePreContractualReceived: (checked: boolean) =>
      pipe(
        commands.termsAndConditionsReceived,
        taskEither.map(() =>
          dispatch(changeDocumentsReceivedConfirmationAction(checked))
        )
      ),
    handleModalMarketingConsentAcceptance: (accepted: boolean) =>
      pipe(
        commands.marketingConsent(accepted),
        taskEither.map(() => dispatch(confirmMarketingConsentAction(accepted)))
      ),
    handleForeignSigningConsentAcceptance: (accepted: boolean) =>
      commands.foreignSigningConsent
        ? pipe(
            commands.foreignSigningConsent(accepted),
            taskEither.map(() =>
              dispatch(changeForeignSigningConsentAction(accepted))
            )
          )
        : constVoid,
  };
};

export const mediationInsuranceDocset = "uKontoInsuranceMediation";

export function getSmartKeyInitialMode(
  authenticationMethod: Option<ExistingClientAuthenticationMethod>
): Option<SmartKeyMode> {
  return pipe(
    authenticationMethod,
    option.chain(value => {
      switch (value) {
        case "QR":
          return option.some("qr");
        case "PUSH_NOTIFICATION":
          return option.some("push");
        default:
          return option.none;
      }
    })
  );
}
