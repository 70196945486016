import { ContentRow, Box, Button, LocalizedString } from "design-system";
import { IO } from "fp-ts/IO";
import { DeepLink, useSBDeepLink } from "../useSBDeepLink";
import { SBAuthorizationResultWaiting } from "./SBAuthorizationResultWaiting";
import { useState } from "react";
import { Path } from "../../APICall";
import { option, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { Option } from "fp-ts/Option";
import { usePortalStatusContext } from "../../PortalStatusContext";

type Props = {
  label: LocalizedString;
  deepLink: DeepLink;
  onProcessStart: () => unknown;
  processStarted: boolean;
  beforeAuthorizationAction: IO<unknown>;
  onCancelSignature: () => unknown;
  checkAuthorizationPushPath: Path;
  canStartProcess: Option<TaskEither<unknown, boolean>>;
} & {
  onSuccess: () => unknown;
};

export function SBAuthorizationButton(props: Props) {
  const sbAuthorize = useSBDeepLink(props.deepLink);
  const [sbAuthorizeCalled, setSbAuthorizeCalled] = useState(false);
  const { portalBlocked } = usePortalStatusContext();

  if (props.processStarted && !sbAuthorizeCalled) {
    setSbAuthorizeCalled(true);
    props.beforeAuthorizationAction();
    sbAuthorize();
  }

  const cancelSignatureRequest = () => {
    props.onCancelSignature();
    setSbAuthorizeCalled(false);
  };

  const onSignButtonClicked = () =>
    pipe(
      props.canStartProcess,
      option.fold(props.onProcessStart, canStartProcess =>
        pipe(
          canStartProcess,
          taskEither.map(canStartProcess =>
            canStartProcess ? props.onProcessStart() : constVoid
          )
        )()
      )
    );

  return (
    <ContentRow type="lateral-margins">
      <Box column grow>
        {props.processStarted ? (
          <SBAuthorizationResultWaiting
            onSuccess={props.onSuccess}
            onCancel={cancelSignatureRequest}
            onFailure={cancelSignatureRequest}
            checkAuthorizationPushPath={props.checkAuthorizationPushPath}
          />
        ) : (
          <Button
            variant="primary"
            label={props.label}
            action={onSignButtonClicked}
            size="default"
            disabled={portalBlocked}
          />
        )}
      </Box>
    </ContentRow>
  );
}
