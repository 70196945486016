import { eq } from "fp-ts";
import * as t from "io-ts";
import { nonEmptyArray } from "io-ts-types/lib/nonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { DocumentMeta, PreContractualDocumentMeta } from "../documentAPI";

export const ContractReadOutput = t.unknown;
export type ContractReadOutput = t.TypeOf<typeof ContractReadOutput>;

export const DocumentsListOutput = nonEmptyArray(DocumentMeta);
export type DocumentsListOutput = t.TypeOf<typeof DocumentsListOutput>;

export const PreContractualDocumentsListOutput = nonEmptyArray(
  PreContractualDocumentMeta
);
export type PreContractualDocumentsListOutput = t.TypeOf<
  typeof PreContractualDocumentsListOutput
>;

export const ContractReadInput = t.type({
  docId: NonEmptyString,
  documentRead: t.boolean,
});
export type ContractReadInput = t.TypeOf<typeof ContractReadInput>;

export const eqContractReadInput = eq.getStructEq({
  docId: eq.eqString,
});

export const TermsAndConditionsReceivedOutput = t.unknown;
export type TermsAndConditionsReceivedOutput = t.TypeOf<
  typeof TermsAndConditionsReceivedOutput
>;

export const MarketingConsentReadInput = t.type({
  accepted: t.boolean,
});
export type MarketingConsentReadInput = t.TypeOf<
  typeof MarketingConsentReadInput
>;

export const eqMarketingConsentReadInput = eq.getStructEq({
  accepted: eq.eqBoolean,
});

export const ForeignSigningConsentReadInput = t.type({
  accepted: t.boolean,
});
export type ForeignSigningConsentReadInput = t.TypeOf<
  typeof ForeignSigningConsentReadInput
>;

export const eqForeignSigningConsentReadInput = eq.getStructEq({
  accepted: eq.eqBoolean,
});

export const PhoneNumberOutput = t.type({
  phoneNumber: t.string,
});
export type PhoneNumberOutput = t.TypeOf<typeof PhoneNumberOutput>;

export const TermsAndConditionsOutput = nonEmptyArray(DocumentMeta);
export type TermsAndConditionsOutput = t.TypeOf<
  typeof TermsAndConditionsOutput
>;
