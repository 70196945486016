import { Box, buttonLink, Body } from "design-system";
import { useFormatMessage } from "../../intl";
import { useState } from "react";
import { FilePDFDialog } from "../Dialogs/FilePDFDialog/FilePDFDialog";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";
import { useCommand } from "../../useAPI";
import * as documentAPI from "../../Common/documentAPI";
import { palette } from "design-system/lib/styleConstants";

type Props = {
  align: "center" | "left";
};

export function PrivacyPolicyDisclaimer(props: Props) {
  const formatMessage = useFormatMessage();

  const [dataModalOpen, setDataModalOpen] = useState(false);

  const privacyPolicyTemplates = useCommand(documentAPI.templates);
  const privacyPolicyContent = useCommand(documentAPI.content);
  const privacyPolicyDownload = useCommand(documentAPI.downloadDocument);

  return (
    <Box>
      <Body
        size="small"
        weight="regular"
        align={props.align}
        color={palette.neutral700}
      >
        {[
          formatMessage("PrivacyPolicyDisclaimer.byContinuingDisclaimer"),
          buttonLink(
            () => setDataModalOpen(true),
            formatMessage("PrivacyPolicyDisclaimer.privacyPolicy")
          ),
        ]}
      </Body>
      {dataModalOpen && (
        <FilePDFDialog
          onDismiss={() => setDataModalOpen(false)}
          title={formatMessage("PrivacyPolicyDisclaimer.privacyPolicyTitle")}
          file={pipe(
            privacyPolicyTemplates({
              docsets: ["privacyPolicy"],
            }),
            taskEither.chain(items =>
              privacyPolicyContent({ docId: items[0].docId })
            )
          )}
          downloadAction={pipe(
            privacyPolicyTemplates({
              docsets: ["privacyPolicy"],
            }),
            taskEither.chain(items =>
              privacyPolicyDownload({
                docId: items[0].docId,
                fileName: formatMessage(
                  "PrivacyPolicyDisclaimer.privacyPolicyFileName"
                ),
              })
            ),
            taskEither.map(({ filePath }) => filePath)
          )}
        />
      )}
    </Box>
  );
}
