import { useEffect } from "react";

export const useVirtualFormSubmit = (cb: () => void) => {
  useEffect(() => {
    const handleEnter = (e: KeyboardEvent) => {
      if (e.key === "Enter" && e.isTrusted === false) {
        cb();
      }
    };
    document.addEventListener("keydown", handleEnter);

    return () => {
      document.removeEventListener("keydown", handleEnter);
    };
  });
};
