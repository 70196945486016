import * as t from "io-ts";
import { apiCall } from "../../APICall";
import { eq, option } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { optionFromUndefined } from "../../globalDomain";
import { withFallback } from "io-ts-types/lib/withFallback";

const CurrentPortalStatus = t.union(
  [
    t.type({
      currentlyBlocked: t.literal(true),
      portalBlockScheduled: t.literal(false),
      timeFrom: withFallback(optionFromUndefined(t.number), option.none),
      timeTo: withFallback(optionFromUndefined(t.number), option.none),
    }),
    t.type({
      currentlyBlocked: t.literal(false),
      portalBlockScheduled: t.literal(true),
      timeFrom: withFallback(optionFromUndefined(t.number), option.none),
      timeTo: withFallback(optionFromUndefined(t.number), option.none),
    }),
    t.type({
      currentlyBlocked: t.literal(false),
      portalBlockScheduled: t.literal(false),
    }),
  ],
  "CurrentPortalStatus"
);
export type CurrentPortalStatus = t.TypeOf<typeof CurrentPortalStatus>;

export const currentPortalStatus = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: ["utilities", "noauth", "cached", "currentPortalStatus"],
  inputCodec: t.void,
  outputCodec: CurrentPortalStatus,
});
