import {
  AutocompleteInputField,
  InternalPhoneNumberField,
} from "design-system";
import { usePhoneNumberFieldConfig } from "./usePhoneNumberFieldProps";

type Props = Omit<
  React.ComponentProps<typeof AutocompleteInputField>,
  "suggestionsStatus" | "onSelectSuggestion" | "loadingMessage"
>;

export function PhoneNumberField(props: Props) {
  const config = usePhoneNumberFieldConfig();

  return <InternalPhoneNumberField {...config} {...props} />;
}
