import { Body, CheckIcon, FileContent, Loader, Stack } from "design-system";
import { palette, spaceUnit } from "design-system/lib/styleConstants";
import { useFormatMessage } from "../intl";
import { ImageViewer } from "../Common/DocumentViewer/DocumentViewer";
import { useCommand } from "../useAPI";
import * as api from "./api";
import { useEffect, useState } from "react";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";
import { DigitalIdClientIdentified } from "./api";

type Props = {
  onClientIdentified: () => unknown;
};

export function DigitalIdClientProfile({ onClientIdentified }: Props) {
  const formatMessage = useFormatMessage();
  const [
    clientIdentified,
    setClientIdentified,
  ] = useState<DigitalIdClientIdentified>();
  const getClientIdentified = useCommand(api.digitalIdClientIdentified);

  useEffect(() => {
    pipe(
      getClientIdentified(),
      taskEither.map(r => {
        setClientIdentified(r);
        onClientIdentified();
      })
    )();
  }, []);

  if (!clientIdentified) {
    return <Loader />;
  }

  return (
    <>
      <Stack units={4} column hAlignContent="center">
        <div
          style={{
            height: "56px",
            width: "56px",
            padding: spaceUnit * 2,
            display: "inline-block",
            borderRadius: "50%",
            border: palette.blue600,
            backgroundColor: palette.blue600,
          }}
        >
          <CheckIcon size="x-large" color={palette.white} />
        </div>
        <Body size="large" weight="bold">
          {formatMessage("DigitalId.ClientIdentified")}
        </Body>
        <ImageViewer
          type="image/png"
          name="clientProfile"
          data={clientIdentified.photo as FileContent}
        />
      </Stack>
    </>
  );
}
