import { taskEither } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { FilePDFDialog } from "../Dialogs/FilePDFDialog/FilePDFDialog";
import { useCommand } from "../../useAPI";
import * as documentAPI from "../documentAPI";

type Props = {
  onDismiss: () => unknown;
};

export function MarketingConsentDialog(props: Props) {
  const formatMessage = useFormatMessage();

  const marketingConsentTemplates = useCommand(documentAPI.templates);
  const marketingConsentContent = useCommand(documentAPI.content);

  return (
    <FilePDFDialog
      onDismiss={props.onDismiss}
      title={formatMessage("UKonto.ReadDocuments.marketingConsentTitle")}
      file={pipe(
        marketingConsentTemplates({
          docsets: ["marketingConsent"],
        }),
        taskEither.chain(items =>
          marketingConsentContent({ docId: items[0].docId })
        )
      )}
    />
  );
}
