import {
  Box,
  Button,
  Checkbox,
  FileIcon,
  Space,
  Stack,
  StatusLabel,
  useIsMobileLayout,
} from "design-system";
import { option } from "fp-ts";
import { constFalse, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { PreContractualDocumentMeta } from "../documentAPI";
import { DocumentReadStatus, mediationInsuranceDocset } from "./utils";

export type DocumentReadingConfirmProps =
  | {
      readingConfirmationType: "none";
    }
  | {
      readingConfirmationType: "status";
      readStatus: DocumentReadStatus;
    }
  | {
      readingConfirmationType: "checkbox";
      isRead: boolean;
      onRead: () => unknown;
      onUnread: () => unknown;
      disabled: boolean;
    };

type Props = {
  document: PreContractualDocumentMeta;
  dataTestId: string;
  showErrors: boolean;
  onDocumentOpen: (document: PreContractualDocumentMeta) => unknown;
  isForeignClient?: boolean;
  onForeignDocumentOpen: (document: PreContractualDocumentMeta) => unknown;
} & DocumentReadingConfirmProps;

export function DocumentItem(props: Props) {
  const isMobileLayout = useIsMobileLayout();
  const formatMessage = useFormatMessage();

  const rightContent = (() => {
    switch (props.readingConfirmationType) {
      case "none":
        return null;
      case "status":
        switch (props.readStatus) {
          case "Read":
            return (
              <StatusLabel
                status="completed"
                label={formatMessage("CreateProfileParent.status.done")}
              />
            );
          case "Reading":
            return (
              <StatusLabel
                status="uncompleted"
                label={formatMessage("CreateProfileParent.status.in.progress")}
              />
            );
          case "Unread":
            return (
              <StatusLabel
                status="undone"
                label={formatMessage("CreateProfileParent.status.following")}
              />
            );
        }
      case "checkbox":
        return (
          <Checkbox
            name={`read-document-${props.document.docId}`}
            label={option.some(
              pipe(
                props.document.docset,
                option.exists(item => item === mediationInsuranceDocset)
              )
                ? formatMessage(
                    "UKonto.ReadDocuments.Modal.checkbox.uKontoInsuranceMediation"
                  )
                : formatMessage("UKonto.ReadDocuments.clientHasRead")
            )}
            value={props.isRead}
            onChange={() => {
              if (props.isRead) {
                props.onUnread();
              } else {
                props.onDocumentOpen(props.document);
              }
            }}
            issuesType={
              props.showErrors && !props.isRead
                ? option.some("error")
                : option.none
            }
            disabled={props.disabled}
          />
        );
    }
  })();

  const stackProps = isMobileLayout
    ? {
        column: true,
        units: 6,
      }
    : ({
        column: false,
        fluid: true,
        vAlignContent: "center",
      } as
        | { column: boolean; units: number }
        | { column: boolean; fluid: true; vAlignContent: "center" });

  const isFrameWorkAgreement = (document: PreContractualDocumentMeta) =>
    pipe(
      document.docType,
      option.fold(constFalse, docType => docType === "FRAMEWORK_AGREEMENT")
    );

  const isProductContract = (document: PreContractualDocumentMeta) =>
    pipe(
      document.docType,
      option.fold(constFalse, docType => docType === "PRODUCT_CONTRACT_ACCOUNT")
    );

  return (
    <Stack {...stackProps} grow data-test-id={props.dataTestId}>
      <Box column style={{ alignItems: "start" }}>
        <Button
          variant="text"
          label={props.document.docTitle}
          icon={FileIcon}
          size="small"
          action={() => props.onDocumentOpen(props.document)}
          disabled={
            props.readingConfirmationType === "checkbox" && props.disabled
          }
        />
        {props.isForeignClient &&
          (isFrameWorkAgreement(props.document) ||
            isProductContract(props.document)) && (
            <Box>
              <Space units={4} />
              <Button
                variant="text"
                label={formatMessage(
                  "UKonto.ReadDocuments.foreignContractsLabel"
                )}
                icon={FileIcon}
                size="small"
                action={() => props.onForeignDocumentOpen(props.document)}
                disabled={
                  props.readingConfirmationType === "checkbox" && props.disabled
                }
              />
            </Box>
          )}
      </Box>
      {rightContent}
    </Stack>
  );
}
