import { useEffect } from "react";

type Behavior = "smooth" | "auto";

export function useScrollTopOnChange(behavior: Behavior, ref?: unknown) {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior,
    });
  }, [ref, behavior]);
}
