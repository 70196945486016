import {
  Body,
  Box,
  LoadingButton,
  Space,
  Stack,
  WrongIcon,
} from "design-system";
import { useFormatMessage } from "../intl";
import { DigitalIdInstructions } from "./DigitalIdInstructions";
import { TaskEither } from "fp-ts/TaskEither";
import { palette, spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  onRetry: TaskEither<unknown, unknown>;
};

export function DigitalIdStop({ onRetry }: Props) {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Stack units={4} column hAlignContent="center">
        <Box column hAlignContent="center">
          <div
            style={{
              height: "56px",
              width: "56px",
              padding: spaceUnit * 2,
              display: "inline-block",
              borderRadius: "50%",
              border: palette.blue800,
              backgroundColor: palette.blue800,
            }}
          >
            <WrongIcon size="x-large" color={palette.white} />
          </div>
          <Space units={4} />
          <Body size={"large"} weight={"bold"}>
            {formatMessage("DigitalId.Stop.Message")}
          </Body>
        </Box>
        <Box vAlignContent="center">
          <Body size="medium" weight="medium">
            {formatMessage("DigitalId.Stop.Button.Message")}
          </Body>
          <Space units={2} />
          <LoadingButton
            size="default"
            labels={{
              normal: formatMessage("DigitalId.Stop.Button.Label"),
              success: formatMessage("DigitalId.Stop.Button.Label"),
              loading: formatMessage("DigitalId.Stop.Button.Label"),
              error: formatMessage("DigitalId.Stop.Button.Label"),
            }}
            variant="text"
            action={onRetry}
          />
        </Box>
      </Stack>
      <DigitalIdInstructions />
    </>
  );
}
