import { Loader } from "design-system";
import { useEffect } from "react";
import { useCommand } from "../useAPI";
import * as api from "./api";
import { pipe } from "fp-ts/function";
import { taskEither } from "fp-ts";

type Props = {
  onContinue: () => unknown;
  applicantIndex?: string;
};

export function DigitalIdStart({ onContinue, applicantIndex }: Props) {
  const start = useCommand(api.digitalIdStart);
  useEffect(() => {
    console.log("applicantIndex", applicantIndex);
    pipe(
      start({ applicantIndex }),
      taskEither.map(() => onContinue())
    )();
  }, []);

  return (
    <>
      <Loader />
    </>
  );
}
