import { Body, Dialog, ErrorIcon } from "design-system";
import { useFormatMessage } from "../../intl";
import { option } from "fp-ts";

export function TabLock() {
  const formatMessage = useFormatMessage();

  return (
    <>
      <Dialog
        icon={ErrorIcon}
        variant="center"
        size="small"
        title={formatMessage("TabLock.modalTitle")}
        onDismiss={option.none}
        actions={[]}
      >
        <Body size="medium" weight="medium">
          {formatMessage("TabLock.anotherOpenTab.label")}
        </Body>
      </Dialog>
    </>
  );
}
