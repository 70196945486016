import { Helmet } from "react-helmet";
import { useFormatMessage } from "./intl";
import { useAppContext } from "./useAppContext";
import { foldTenant } from "./globalDomain";

export function DocumentTitle() {
  const formatMessage = useFormatMessage();
  const {
    config: { environment },
    apiParameters: { tenant },
  } = useAppContext();

  const title = foldTenant(
    tenant,
    () => formatMessage("DocumentTitle.SK"),
    () => formatMessage("DocumentTitle.CZ")
  );

  const envTitle = ((): string => {
    switch (environment) {
      case "dev":
      case "sit":
      case "uat":
      case "hf":
      case "ci":
        return `${environment.toUpperCase()} | ${title}`;
      case "prod":
        return title;
    }
  })();

  return (
    <Helmet>
      <title>{envTitle}</title>
    </Helmet>
  );
}
