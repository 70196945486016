import { set, option, eq, ord, nonEmptyArray } from "fp-ts";
import { pipe } from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { nonEmptyArray as nonEmptyArrayC } from "io-ts-types/lib/nonEmptyArray";
import { DocumentMeta } from "../documentAPI";
import * as t from "io-ts";
import { setFromArray } from "io-ts-types/lib/setFromArray";
import {
  ExistingClientAuthenticationMethod,
  optionFromUndefined,
} from "../../globalDomain";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";
import { option as optionCodec } from "io-ts-types/lib/option";

const UIError = t.literal("DocumentsUnread");
export type UIError = t.TypeOf<typeof UIError>;

const ReadDocumentsState = t.type({
  type: t.literal("ReadDocuments"),
  documentsRead: setFromArray(NonEmptyString, ord.ordString),
  documentsReceivedConfirmation: t.boolean,
  marketingConsent: t.boolean,
  foreignSigningConsent: t.boolean,
  isMarketingConsentModalOpen: t.boolean,
  error: optionFromUndefined(UIError),
  authenticationMethod: optionCodec(ExistingClientAuthenticationMethod),
});
const SigningDocumentsState = t.type({
  type: t.literal("SigningDocuments"),
  authenticationMethod: optionCodec(ExistingClientAuthenticationMethod),
  documentsRead: setFromArray(NonEmptyString, ord.ordString),
  documentsReceivedConfirmation: t.boolean,
  marketingConsent: t.boolean,
  foreignSigningConsent: t.boolean,
});
const State = t.union([ReadDocumentsState, SigningDocumentsState]);

export type State = t.TypeOf<typeof State>;

const ReadDocumentAction = t.type({
  type: t.literal("ReadDocument"),
  payload: NonEmptyString,
});
export function readDocumentAction(documentId: NonEmptyString): Action {
  return {
    type: "ReadDocument",
    payload: documentId,
  };
}

const UnreadDocumentAction = t.type({
  type: t.literal("UnreadDocument"),
  payload: NonEmptyString,
});
export function unreadDocumentAction(documentId: NonEmptyString): Action {
  return {
    type: "UnreadDocument",
    payload: documentId,
  };
}

const ChangeDocumentsReceivedConfirmationAction = t.type({
  type: t.literal("ChangeDocumentsReceivedConfirmation"),
  payload: t.boolean,
});
export function changeDocumentsReceivedConfirmationAction(
  confirm: boolean
): Action {
  return {
    type: "ChangeDocumentsReceivedConfirmation",
    payload: confirm,
  };
}

const ChangeMarketingConsentAction = t.type({
  type: t.literal("ChangeMarketingConsent"),
  payload: t.boolean,
});
export function changeMarketingConsentAction(value: boolean): Action {
  return {
    type: "ChangeMarketingConsent",
    payload: value,
  };
}

const ChangeForeignSigningConsentAction = t.type({
  type: t.literal("ChangeForeignSigningConsent"),
  payload: t.boolean,
});
export function changeForeignSigningConsentAction(value: boolean): Action {
  return {
    type: "ChangeForeignSigningConsent",
    payload: value,
  };
}

const StartSignatureAction = t.type({
  type: t.literal("StartSignature"),
  payload: t.type({
    allDocuments: nonEmptyArrayC(DocumentMeta),
    checkDocumentRead: t.boolean,
    checkMarketingConsent: t.boolean,
    isForeignClient: t.boolean,
  }),
});
export function startSignatureAction(
  allDocuments: NonEmptyArray<DocumentMeta>,
  checkDocumentRead: boolean,
  checkMarketingConsent: boolean,
  isForeignClient: boolean
): Action {
  return {
    type: "StartSignature",
    payload: {
      allDocuments,
      checkDocumentRead,
      checkMarketingConsent,
      isForeignClient,
    },
  };
}

const ConfirmMarketingConsentAction = t.type({
  type: t.literal("ConfirmMarketingConsentAction"),
  payload: t.boolean,
});
export function confirmMarketingConsentAction(confirmation: boolean): Action {
  return {
    type: "ConfirmMarketingConsentAction",
    payload: confirmation,
  };
}

const DismissMarketingModalAction = t.type({
  type: t.literal("DismissMarketingModal"),
});
export function dismissMarketingModalAction(): Action {
  return {
    type: "DismissMarketingModal",
  };
}
const CancelSignatureAction = t.type({
  type: t.literal("CancelSignature"),
});

export const Action = t.union([
  ReadDocumentAction,
  UnreadDocumentAction,
  ChangeDocumentsReceivedConfirmationAction,
  ChangeMarketingConsentAction,
  ChangeForeignSigningConsentAction,
  StartSignatureAction,
  ConfirmMarketingConsentAction,
  DismissMarketingModalAction,
  CancelSignatureAction,
]);
export type Action = t.TypeOf<typeof Action>;

function checkHasReadAllContracts(
  allDocuments: NonEmptyArray<DocumentMeta>,
  documentsRead: Set<NonEmptyString>
): boolean {
  const unreadDocuments = pipe(
    allDocuments,
    nonEmptyArray.map(document => document.docId),
    set.fromArray(eq.eqString),
    set.difference(eq.eqString)(documentsRead)
  );
  return unreadDocuments.size === 0;
}

function checkCanStartSignature(
  allDocuments: NonEmptyArray<DocumentMeta>,
  documentsRead: Set<NonEmptyString>,
  confirmDocumentsReceived: boolean,
  isForeignClient: boolean,
  foreignSigningConsent: boolean
): boolean {
  return isForeignClient
    ? checkHasReadAllContracts(allDocuments, documentsRead) &&
        confirmDocumentsReceived &&
        foreignSigningConsent
    : checkHasReadAllContracts(allDocuments, documentsRead) &&
        confirmDocumentsReceived;
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "ReadDocument":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          documentsRead: pipe(
            state.documentsRead,
            set.insert<NonEmptyString>(eq.eqString)(action.payload)
          ),
          error: option.none,
        };
      } else return state;
    case "UnreadDocument":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          documentsRead: pipe(
            state.documentsRead,
            set.remove<NonEmptyString>(eq.eqString)(action.payload)
          ),
          error: option.none,
        };
      } else return state;
    case "ChangeDocumentsReceivedConfirmation":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          documentsReceivedConfirmation: action.payload,
          error: action.payload ? option.none : state.error,
        };
      } else return state;
    case "ChangeMarketingConsent":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          marketingConsent: action.payload,
        };
      } else return state;
    case "ChangeForeignSigningConsent":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          foreignSigningConsent: action.payload,
        };
      } else return state;
    case "StartSignature":
      if (state.type === "ReadDocuments") {
        if (
          !action.payload.checkDocumentRead ||
          checkCanStartSignature(
            action.payload.allDocuments,
            state.documentsRead,
            state.documentsReceivedConfirmation,
            action.payload.isForeignClient,
            state.foreignSigningConsent
          )
        ) {
          if (state.marketingConsent || !action.payload.checkMarketingConsent) {
            return {
              type: "SigningDocuments",
              marketingConsent: state.marketingConsent,
              authenticationMethod: state.authenticationMethod,
              documentsRead: state.documentsRead,
              documentsReceivedConfirmation:
                state.documentsReceivedConfirmation,
              foreignSigningConsent: state.foreignSigningConsent,
            };
          } else {
            return {
              ...state,
              isMarketingConsentModalOpen: true,
            };
          }
        } else {
          return {
            ...state,
            error: option.some("DocumentsUnread"),
          };
        }
      } else return state;
    case "ConfirmMarketingConsentAction":
      if (state.type === "ReadDocuments") {
        return {
          type: "SigningDocuments",
          marketingConsent: action.payload,
          authenticationMethod: state.authenticationMethod,
          documentsRead: state.documentsRead,
          documentsReceivedConfirmation: state.documentsReceivedConfirmation,
          foreignSigningConsent: state.foreignSigningConsent,
        };
      } else return state;
    case "DismissMarketingModal":
      if (state.type === "ReadDocuments") {
        return {
          ...state,
          isMarketingConsentModalOpen: false,
        };
      } else {
        return state;
      }
    case "CancelSignature":
      if (state.type === "SigningDocuments") {
        return {
          ...state,
          type: "ReadDocuments",
          isMarketingConsentModalOpen: false,
          error: option.none,
        };
      } else {
        return state;
      }
  }
}

export const reducerConfig = sharedReducerConfig(
  "PreContractualDocuments",
  State,
  Action,
  reducer
);
