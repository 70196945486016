import { ReadOnlyField } from "design-system";
import { constant, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import {
  DropdownField,
  DropdownFieldProps,
} from "../DropdownField/DropdownField";
import { ComponentProps } from "react";

type Feedback = ComponentProps<typeof ReadOnlyField>["feedback"];

type Props<T> = DropdownFieldProps<T> & {
  edit: boolean;
  feedback?: Feedback;
};

export function OptionalEditDropdownField<T>(props: Props<T>) {
  const { edit, ...fieldProps } = props;

  if (edit) return <DropdownField {...fieldProps} />;

  const value = pipe(
    props.value,
    option.map(({ label }) => String(label)),
    option.getOrElse(constant(""))
  );

  return (
    <ReadOnlyField
      size="medium"
      label={props.label}
      value={value}
      feedback={props.feedback}
    />
  );
}
