import { Reader } from "fp-ts/Reader";
import {
  ExistingClientAuthenticationMethod,
  GenericError,
} from "../../globalDomain";
import {
  SmartKey,
  SmartKeyChild,
  SmartKeyProps,
} from "../../SmartKey/SmartKey";
import { ExternalPushNotificationCard } from "./ExternalPushNotificationCard";
import {
  OtpGenerationError,
  OtpGenerationInput,
  OtpGenerationOutput,
  OtpVerifyError,
  OtpVerifyInput,
} from "../../OTP/domain";
import { Path } from "../../APICall";
import * as smartKeyApi from "../../SmartKey/api";
import { SBAuthorizationButton } from "./SBAuthorizationButton";
import { IO } from "fp-ts/IO";
import {
  OTPAuthorization,
  OTPAuthorizationChild,
} from "../../OTPAuthorization/OTPAuthorization";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { Option } from "fp-ts/Option";
import {
  LocalizedString,
  unsafePositiveInteger,
  Banner,
  Stack,
  useIsTouchScreen,
} from "design-system";
import { useFormatMessage } from "../../intl";
import { pipe } from "fp-ts/function";
import { option, taskEither } from "fp-ts";

import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { OTPAuthorizationParent } from "../../OTPAuthorization/OTPAuthorizationParent";
import { SmartKeyMode } from "../../SmartKey/SmartKeyState";
import { SmartKeyParent } from "../../SmartKey/SmartKeyParent";
import { TaskEither } from "fp-ts/TaskEither";

export type IDProps = {
  authenticationMethod: Extract<ExistingClientAuthenticationMethod, "ID">;
  phoneNumber: string;
  generateOtpCommand: ReaderTaskEither<
    OtpGenerationInput,
    OtpGenerationError | GenericError,
    OtpGenerationOutput & { transactionId: Option<LocalizedString> }
  >;
  verifyOtpCommand: ReaderTaskEither<
    OtpVerifyInput,
    OtpVerifyError | GenericError,
    unknown
  >;
  onSignFailure: () => unknown;
};

export type PushNotificationAndQRProps = {
  authenticationMethod: Extract<
    ExistingClientAuthenticationMethod,
    "PUSH_NOTIFICATION" | "QR"
  >;
  authorizeWithPush: ReaderTaskEither<
    void,
    unknown,
    smartKeyApi.SendPushOutput
  >;
  checkAuthorizationPushPath: Path;
  generateAuthorizationQR: ReaderTaskEither<
    void,
    unknown,
    smartKeyApi.GenerateQRCodeOutput
  >;
  checkAuthorizationQRPath: Path;
  onFailedSignature: () => unknown;
};

export type ExternalPushNotificationProps = {
  authenticationMethod: Extract<
    ExistingClientAuthenticationMethod,
    "EXTERNAL_PUSH_NOTIFICATION"
  >;
  onApplicationRejected: () => unknown;
  authorizeWithPushTLS: ReaderTaskEither<
    void,
    unknown,
    smartKeyApi.SendPushNoTransactionOutput
  >;
};

export type PINProps = {
  authenticationMethod: Extract<ExistingClientAuthenticationMethod, "PIN">;
  beforeAuthorizationAction: IO<unknown>;
  checkAuthorizationPushPath: Path;
  canStartAuthorization?: TaskEither<unknown, boolean>;
};

export type SignatureProps =
  | IDProps
  | PushNotificationAndQRProps
  | ExternalPushNotificationProps
  | PINProps;

type Props = {
  onContinue: () => unknown;
  startSignature: () => unknown;
  onCancelSignature: () => unknown;
  isSignatureStarted: boolean;
  signatureProps: SignatureProps;
  smartKeyInitialMode?: SmartKeyMode;
  otpLength?: number;
  hideRequestNotice?: boolean;
};

function foldPropsAuthenticationMethod<T>(matches: {
  ID: Reader<IDProps, T>;
  PUSH_NOTIFICATION: Reader<PushNotificationAndQRProps, T>;
  QR: Reader<PushNotificationAndQRProps, T>;
  EXTERNAL_PUSH_NOTIFICATION: Reader<ExternalPushNotificationProps, T>;
  PIN: Reader<PINProps, T>;
}): Reader<Props, T> {
  return props => {
    switch (
      props.signatureProps.authenticationMethod // NOSONAR
    ) {
      case "ID":
        return matches.ID(props.signatureProps);
      case "PUSH_NOTIFICATION":
        return matches.PUSH_NOTIFICATION(props.signatureProps);
      case "QR":
        return matches.QR(props.signatureProps);
      case "PIN":
        return matches.PIN(props.signatureProps);
      case "EXTERNAL_PUSH_NOTIFICATION":
        return matches.EXTERNAL_PUSH_NOTIFICATION(props.signatureProps);
    }
  };
}

export function DocumentsSignature(props: Props) {
  const formatMessage = useFormatMessage();
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const isTouchScreen = useIsTouchScreen();

  return pipe(
    props,
    foldPropsAuthenticationMethod({
      ID: signatureProps => {
        const otpAuthProps = {
          phoneNumber: signatureProps.phoneNumber,
          onSuccess: taskEither.fromIO(props.onContinue),
          onFailure: signatureProps.onSignFailure,
          requestNotice: props.hideRequestNotice
            ? option.none
            : option.some(
                formatMessage("Identification.otp.authorizeWithSMS.subtitle")
              ),
          otpRequestButtonLabel: formatMessage(
            "UKonto.ReadDocuments.sendOTPToSign"
          ),
          otpSubmitButtonLabel: formatMessage("Identification.otp.signViaOTP"),
          otpTitleLabel: formatMessage(
            "Identification.otp.authorizeWithSMS.title"
          ),
          onProcessStart: taskEither.fromIO(() => props.startSignature()),
          processStarted: props.isSignatureStarted,
          onGenerateOTP: signatureProps.generateOtpCommand,
          onVerifyOTP: signatureProps.verifyOtpCommand,
          length: props.otpLength
            ? unsafePositiveInteger(props.otpLength)
            : unsafePositiveInteger(4),
        };

        if (isTouchScreen) {
          return (
            <Stack column units={10}>
              <Banner
                type="informative"
                title={option.none}
                onDismiss={option.none}
                actions={option.none}
                content={formatMessage(
                  "DocumentsSignature.OTPAuthorization.infoBanner"
                )}
              />
              <OTPAuthorizationChild {...otpAuthProps} allowResendOTP={false} />
            </Stack>
          );
        }
        if (branchExperienceFeaturesActive) {
          return <OTPAuthorizationParent {...otpAuthProps} />;
        } else {
          return <OTPAuthorization {...otpAuthProps} allowResendOTP />;
        }
      },
      QR: signatureProps => {
        const smartKeyProps: SmartKeyProps = {
          variant: "authorization",
          onSuccess: props.onContinue,
          onProcessStart: props.startSignature,
          processStarted: props.isSignatureStarted,
          authorizeWithPush: signatureProps.authorizeWithPush,
          checkAuthorizationPushPath: signatureProps.checkAuthorizationPushPath,
          generateAuthorizationQR: signatureProps.generateAuthorizationQR,
          checkAuthorizationQRPath: signatureProps.checkAuthorizationQRPath,
          onFailedSignature: signatureProps.onFailedSignature,
          initialMode: props.smartKeyInitialMode,
        };
        if (isTouchScreen) {
          return <SmartKeyChild {...smartKeyProps} />;
        }
        if (branchExperienceFeaturesActive) {
          return <SmartKeyParent {...smartKeyProps} />;
        } else {
          return <SmartKey {...smartKeyProps} />;
        }
      },
      PUSH_NOTIFICATION: signatureProps => {
        const smartKeyProps: SmartKeyProps = {
          variant: "authorization",
          onSuccess: props.onContinue,
          onProcessStart: props.startSignature,
          processStarted: props.isSignatureStarted,
          authorizeWithPush: signatureProps.authorizeWithPush,
          checkAuthorizationPushPath: signatureProps.checkAuthorizationPushPath,
          generateAuthorizationQR: signatureProps.generateAuthorizationQR,
          checkAuthorizationQRPath: signatureProps.checkAuthorizationQRPath,
          onFailedSignature: signatureProps.onFailedSignature,
          initialMode: props.smartKeyInitialMode,
        };
        if (isTouchScreen) {
          return <SmartKeyChild {...smartKeyProps} />;
        }
        if (branchExperienceFeaturesActive) {
          return <SmartKeyParent {...smartKeyProps} />;
        } else {
          return <SmartKey {...smartKeyProps} />;
        }
      },
      EXTERNAL_PUSH_NOTIFICATION: signatureProps => {
        return (
          <ExternalPushNotificationCard
            onContinue={props.onContinue}
            onApplicationRejected={signatureProps.onApplicationRejected}
            authorizeWithPushTLS={signatureProps.authorizeWithPushTLS}
          />
        );
      },
      PIN: signatureProps => {
        return (
          <SBAuthorizationButton
            label={formatMessage("Identification.deeplink.signContract")}
            deepLink="PAL-authorize"
            onProcessStart={props.startSignature}
            processStarted={props.isSignatureStarted}
            onCancelSignature={props.onCancelSignature}
            beforeAuthorizationAction={signatureProps.beforeAuthorizationAction}
            onSuccess={props.onContinue}
            checkAuthorizationPushPath={
              signatureProps.checkAuthorizationPushPath
            }
            canStartProcess={
              signatureProps.canStartAuthorization
                ? option.some(signatureProps.canStartAuthorization)
                : option.none
            }
          />
        );
      },
    })
  );
}
