import * as t from "io-ts";
import {
  sharedReducerConfig,
  useChildSharedReducer,
  useParentSharedReducer,
} from "../BranchExperience/useSharedReducer";
import { Dispatch } from "react";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

const State = t.union([
  t.type({
    qrCode: t.string,
  }),
  t.undefined,
]);

export type State = t.TypeOf<typeof State>;

const UpdateAction = t.type({
  type: t.literal("UpdateAction"),
  payload: State,
});

const Action = UpdateAction;

export type Action = t.TypeOf<typeof Action>;

function reducer(_: State, action: Action): State {
  switch (action.type) {
    case "UpdateAction":
      return action.payload;
  }
}

function digitalIdReducerConfig() {
  return sharedReducerConfig("DigitalId", State, Action, reducer);
}

export function useDigitalIdParentReducer(
  initState?: State
): [State, Dispatch<State>] {
  const [state, dispatch] = useParentSharedReducer(
    digitalIdReducerConfig(),
    initState
  );
  const setState = (s: State) => dispatch({ type: "UpdateAction", payload: s });

  return [state, setState];
}

export function useDigitalIdChildReducer(): [State, Dispatch<State>] {
  const [state, dispatch] = useChildSharedReducer(digitalIdReducerConfig());
  const setState = (s: State) => dispatch({ type: "UpdateAction", payload: s });

  return [
    pipe(
      state,
      option.fold(
        () => undefined,
        state => state
      )
    ),
    setState,
  ];
}
