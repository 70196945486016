import { withFallback } from "io-ts-types/lib/withFallback";
import { apiCall } from "../APICall";
import * as t from "io-ts";
import * as eq from "fp-ts/Eq";
import { FileContent, LocalizedString } from "design-system";
import { nonEmptyArray as nonEmptyArrayCodec } from "io-ts-types/lib/nonEmptyArray";
import { nonEmptyArray } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { optionFromUndefined } from "../globalDomain";
import { optionFromNullable } from "io-ts-types/optionFromNullable";

export const DocumentSetId = t.keyof(
  {
    privacyPolicy: true,
    personalDataProcessing: true,
    overdraftInformation: true,
    marketingConsent: true,
    representativeExample: true,
    creditBureauMemorandum: true,
    dataProcessingRegisters: true,
    loanInsurance: true,
    mtgLoanInsurance: true,
    branchContactData: true,
    PackageInsuranceInfo: true,
    CLcreditBureauMemorandum: true,
    DBN_SD_056: true,
    DBN_SD_059: true,
    "3PCertificationAtLogin": true,
    DBN_SD_055: true,
    "3PCertificationAtLoanStart": true,
    OVDRepresentativeExample: true,
  },
  "Docset"
);

export type Docset = t.TypeOf<typeof DocumentSetId>;

export const eqDocset: eq.Eq<Docset> = eq.eqStrict;

export const DocumentMeta = t.type(
  {
    docId: NonEmptyString,
    docTitle: LocalizedString,
    docset: optionFromUndefined(NonEmptyString),
    downloadUrl: optionFromUndefined(NonEmptyString),
    // SBL-45709
    // this is actually used only for /ukonto/documents and it is required there
    // to reuse all the implementation of PrecontractualDocuments, contractualDocument has been added here as Option<Boolean>
    // then PrecontractualDocuments filters documents which have contractualDocument = true or without contractualDocument property
    contractualDocument: optionFromUndefined(t.boolean),
    docType: optionFromUndefined(NonEmptyString),
  },
  "DocumentMeta"
);

export type DocumentMeta = t.TypeOf<typeof DocumentMeta>;

export const PreContractualDocumentMeta = t.intersection(
  [
    DocumentMeta,
    t.type({
      signatureRequired: withFallback(t.boolean, true),
      downloadUrl: optionFromUndefined(NonEmptyString),
      // SBL-45709 -> see the comment above
      contractualDocument: optionFromUndefined(t.boolean),
    }),
  ],
  "PreContractualDocumentMeta"
);
export type PreContractualDocumentMeta = t.TypeOf<
  typeof PreContractualDocumentMeta
>;

export const DocumentTemplatesInput = t.type(
  {
    docsets: nonEmptyArrayCodec(DocumentSetId),
  },
  "DocumentTemplatesInput"
);

interface DocumentTemplatesInput
  extends t.TypeOf<typeof DocumentTemplatesInput> {}

export const eqDocumentTemplatesInput = eq.getStructEq({
  docsets: nonEmptyArray.getEq(eqDocset),
});

export const DocumentTemplatesOutput = nonEmptyArrayCodec(DocumentMeta);

export interface DocumentTemplatesOutput
  extends t.TypeOf<typeof DocumentTemplatesOutput> {}

export const templates = apiCall({
  inputEq: eqDocumentTemplatesInput,
  path: ["document", "templates"],
  inputCodec: DocumentTemplatesInput,
  outputCodec: DocumentTemplatesOutput,
});

export const DocumentContentInput = t.type(
  {
    docId: NonEmptyString,
  },
  "DocumentContentInput"
);

export const CpiApplicationFormDocumentOutput = t.type({
  pdfContent: FileContent,
  pdfName: LocalizedString,
});

interface DocumentContentInput extends t.TypeOf<typeof DocumentContentInput> {}

export const eqDocumentContentInput = eq.getStructEq({
  docId: eq.eqString,
});

export const DocumentContentOutput = t.type(
  {
    filename: LocalizedString,
    content: FileContent,
    downloadUrl: optionFromUndefined(NonEmptyString),
  },
  "DocumentContentOutput"
);

export interface DocumentContentOutput
  extends t.TypeOf<typeof DocumentContentOutput> {}

export const content = apiCall({
  inputEq: eqDocumentContentInput,
  path: ["document", "content"],
  inputCodec: DocumentContentInput,
  outputCodec: DocumentContentOutput,
});

export const contentDocMTG = apiCall({
  inputEq: eqDocumentContentInput,
  path: [
    "packages",
    "mortgage",
    "additionaldetails",
    "health",
    "document",
    "content",
  ],
  inputCodec: DocumentContentInput,
  outputCodec: DocumentContentOutput,
});

export const contentSealed = apiCall({
  inputEq: eqDocumentContentInput,
  path: ["document", "contentSealed"],
  inputCodec: DocumentContentInput,
  outputCodec: DocumentContentOutput,
});

export const DocumentDownloadInput = t.type(
  {
    docId: NonEmptyString,
    fileName: LocalizedString,
  },
  "DocumentDownloadInput"
);

interface DocumentDownloadInput
  extends t.TypeOf<typeof DocumentDownloadInput> {}

export const eqDocumentDownloadInput = eq.getStructEq({
  docId: eq.eqString,
  fileName: eq.eqString,
});

export const DocumentDownloadOutput = t.type(
  {
    filePath: t.string,
  },
  "DocumentDownloadOutput"
);

export interface DocumentDownloadOutput
  extends t.TypeOf<typeof DocumentDownloadOutput> {}

export const downloadDocument = apiCall({
  inputEq: eqDocumentDownloadInput,
  path: ["document", "dde", "noauth", "static"],
  inputCodec: DocumentDownloadInput,
  outputCodec: DocumentDownloadOutput,
});

export const DocumentMime = t.keyof({
  "application/pdf": true,
  "image/jpeg": true,
  "image/tiff": true,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": true,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": true,
  "application/vnd.ms-outlook": true,
  ".msg": true,
});
export type DocumentMime = t.TypeOf<typeof DocumentMime>;

const GetDocumentContentOutput = t.type({
  content: FileContent,
  mimeType: DocumentMime,
  filename: NonEmptyString,
  docTypeId: optionFromNullable(NonEmptyString),
  docTypeName: optionFromNullable(NonEmptyString),
  downloadUrl: optionFromUndefined(NonEmptyString),
});
export type GetDocumentContentOutput = t.TypeOf<
  typeof GetDocumentContentOutput
>;

const GetDocumentContentInput = t.type({
  documentID: NonEmptyString,
});

const eqGetDocumentContent = eq.getStructEq({
  documentID: eq.eqString,
});

export const getDocumentContentMortgage = apiCall({
  inputCodec: GetDocumentContentInput,
  inputEq: eqGetDocumentContent,
  outputCodec: GetDocumentContentOutput,
  path: ["packages", "mortgage", "document", "content"],
});

export const getDocumentContentSL = apiCall({
  inputCodec: GetDocumentContentInput,
  inputEq: eqGetDocumentContent,
  outputCodec: GetDocumentContentOutput,
  path: ["packages", "loans", "standard-loan", "document", "content"],
});

export const downloadDocumentContent = apiCall({
  inputCodec: GetDocumentContentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.type({
    /** TODO: Document (download) */
  }),
  path: ["packages", "mortgage", "document", "download"],
});

export const getCpiApplicationFormDocument = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CpiApplicationFormDocumentOutput,
  path: ["packages", "mortgage", "offer", "getCpiApplicationFormDocument"],
});
