import {
  Criteria,
  PasswordStrength,
  passwordCriteriaValidators,
  LocalizedString,
} from "design-system";
import { useFormatMessage } from "../intl";

export const useCriteria = () => {
  const formatMessage = useFormatMessage();

  const lengthRange: (
    minLength: number,
    maxLength: number
  ) => Criteria<string> = (minLength, maxLength) => ({
    label: formatMessage("Form.passwordCriteria.lengthRange", {
      minLength,
      maxLength,
    }),
    validator: passwordCriteriaValidators.lengthRange(minLength, maxLength),
  });

  const minLength: (length: number) => Criteria<string> = length => ({
    label: formatMessage("Form.passwordCriteria.minLength", { length }),
    validator: passwordCriteriaValidators.minLength(length),
  });

  const hasDigit: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.digit"),
    validator: passwordCriteriaValidators.hasDigit,
  };

  const hasSpecialCharacter: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.specialCharacter"),
    validator: passwordCriteriaValidators.hasSpecialCharacter,
  };

  const hasLowerLetter: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.lowercaseLetter"),
    validator: passwordCriteriaValidators.hasLowerLetter,
  };

  const hasCapitalLetter: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.capitalLetter"),
    validator: passwordCriteriaValidators.hasCapitalLetter,
  };

  const noPartialConsecutiveDigits: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.noConsecutiveDigits"),
    validator: passwordCriteriaValidators.noPartialConsecutiveDigits,
  };

  const noDigitRepeatsMoreThanN: (
    maxRepetitions: number
  ) => Criteria<string> = maxRepetitions => ({
    label: formatMessage("Form.passwordCriteria.noDigitRepeatsMoreThanN", {
      times: maxRepetitions,
    }),
    validator: passwordCriteriaValidators.noDigitRepeatsMoreThanN(
      maxRepetitions
    ),
  });

  const noSpecialCharacters: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.noSpecialCharacters"),
    validator: passwordCriteriaValidators.noSpecialCharacters,
  };

  const noDigitFirstCharacter: Criteria<string> = {
    label: formatMessage("Form.passwordCriteria.noDigitFirstCharacter"),
    validator: passwordCriteriaValidators.noDigitFirstCharacter,
  };

  const clientPasswordCriteria = {
    lengthRange: lengthRange(6, 14),
    noDigitFirstCharacter,
    noSpecialCharacters,
  };

  const thirdPartyPasswordCriteria = {
    minLength: minLength(8),
    hasDigit,
    hasLowerLetter,
    hasCapitalLetter,
    hasSpecialCharacter,
  } as const;

  const securityPinCriteria = {
    lengthRange: lengthRange(6, 8),
    noPartialConsecutiveDigits,
    noDigitRepeatsMoreThanN: noDigitRepeatsMoreThanN(2),
  };

  const passwordStrengthLabel = (
    strength: Exclude<PasswordStrength, "weak">
  ): LocalizedString => {
    switch (strength) {
      case "good":
        return formatMessage("Form.passwordField.goodPassword");
      case "strong":
        return formatMessage("Form.passwordField.strongPassword");
    }
  };

  const pinStrengthLabel = (
    strength: Exclude<PasswordStrength, "weak">
  ): LocalizedString => {
    switch (strength) {
      case "good":
        return formatMessage("Form.passwordField.goodPin");
      case "strong":
        return formatMessage("Form.passwordField.strongPin");
    }
  };

  return {
    clientPasswordCriteria,
    thirdPartyPasswordCriteria,
    securityPinCriteria,
    passwordStrengthLabel,
    pinStrengthLabel,
  };
};
