import {
  Box,
  FieldHeader,
  InlineMessage,
  LocalizedString,
  NonNegativeInteger,
  Space,
  useUniqueID,
} from "design-system";
import { ComponentProps } from "react";
import { MoneyAmount } from "../../globalDomain";
import { DisplayMoneyAmount } from "../Price/DisplayMoneyAmount";

type Props = {
  label: LocalizedString;
  value: MoneyAmount;
  inverted?: boolean;
  size: "small" | "x-small" | "input" | "large" | "big";
  fractionDigits?: NonNegativeInteger;
  description?: LocalizedString;
  feedback?: {
    type: ComponentProps<typeof InlineMessage>["type"];
    message: LocalizedString;
  };
};

export function ReadOnlyMoneyAmountField(props: Props) {
  const labelId = useUniqueID();
  return (
    <Box column shrink>
      <FieldHeader
        labelId={labelId}
        label={props.label}
        inverted={props.inverted}
        readonly
        description={props.description}
      />
      <DisplayMoneyAmount
        id={labelId}
        amount={props.value}
        size={props.size}
        inverted={props.inverted}
        fractionDigits={props.fractionDigits}
      />
      {props.feedback && (
        <Box column>
          <Space units={1} />
          <InlineMessage
            size="medium"
            type={props.feedback.type}
            message={props.feedback.message}
          />
        </Box>
      )}
    </Box>
  );
}
