export function getOnlyDownloadMimes(isSafari: boolean) {
  const mimes = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.ms-outlook",
  ];
  if (!isSafari) {
    mimes.push("image/tiff");
  }
  return mimes;
}
