import { Action, FeedbackBlock, LocalizedStringBrand } from "design-system";
import { option } from "fp-ts";
import { constant, pipe } from "fp-ts/function";
import { IO } from "fp-ts/IO";
import { Option } from "fp-ts/Option";
import { ValidateDetailsOutput } from "./api";
import { Branded } from "io-ts";
import { getDialogTexts } from "./utils";
import { useFormatMessage } from "../../intl";

type Props = {
  validationResult: ValidateDetailsOutput;
  onDismiss: Option<IO<unknown>>;
  title: Branded<string, LocalizedStringBrand>;
};

export function UserNotValidatedError(props: Props) {
  const dialogTexts = getDialogTexts(props.validationResult);
  const formatMessage = useFormatMessage();
  return (
    <FeedbackBlock
      size="medium"
      type="info"
      heading={formatMessage(dialogTexts.title)}
      subheading={option.some(formatMessage(dialogTexts.message))}
      actions={pipe(
        props.onDismiss,
        option.fold<IO<unknown>, [] | [Action]>(constant([]), onDismiss => [
          {
            variant: "primary",
            label: formatMessage("UserValidation.button"),
            action: onDismiss,
          },
        ])
      )}
    />
  );
}
