import { Dialog, TextChildren, ProblemIcon, Action } from "design-system";
import { option } from "fp-ts";
import { IO } from "fp-ts/IO";
import { useFormatMessage } from "../../intl";
import { useIsRemoteChannel } from "../../useChannel";

type Props = {
  message: TextChildren;
  onCancel: IO<unknown>;
  onDismiss: IO<unknown>;
  onConfirm: IO<unknown>;
};

export function PortalStatusAlertDialog(props: Props) {
  const isRemote = useIsRemoteChannel();
  const formatMessage = useFormatMessage();

  const stayLoggedAction: Action = {
    label: formatMessage("PortalStatus.modal.blocked.cancel"),
    action: props.onCancel,
    variant: "secondary",
    dark: true,
  };

  const logoutAction: Action = {
    label: formatMessage("PortalStatus.modal.blocked.confirm"),
    action: props.onConfirm,
    variant: "danger",
  };

  const actions: [Action] | [Action, Action] = !isRemote
    ? [stayLoggedAction, logoutAction]
    : [stayLoggedAction];

  return (
    <Dialog
      variant="center"
      size="medium"
      title={formatMessage("PortalStatus.modal.blocked.title")}
      subtitle={props.message}
      icon={ProblemIcon}
      onDismiss={option.some(props.onDismiss)}
      actions={actions}
      iconColor="danger"
      borderColor="danger"
    />
  );
}
