import { ValidateDetailsOutput } from "./api";
import { LocaleKey } from "../../intl";

export function getDialogTexts(
  validationResult: ValidateDetailsOutput
): { title: LocaleKey; message: LocaleKey } {
  if (!validationResult.hasPhoneNumber) {
    return {
      title: "UserValidation.missingPhoneNumberTitle",
      message: "UserValidation.missingPhoneNumberMessage",
    };
  } else if (!validationResult.isPhoneNumberVerified) {
    return {
      title: "UserValidation.phoneNumberNotVerifiedTitle",
      message: "UserValidation.phoneNumberNotVerifiedMessage",
    };
  } else if (!validationResult.hasPasswordForCommunication) {
    return {
      title: "UserValidation.passwordNotSpecifiedTitle",
      message: "UserValidation.passwordNotSpecifiedMessage",
    };
  } else if (!validationResult.hasEmailAddress) {
    return {
      title: "UserValidation.missingEmailTitle",
      message: "UserValidation.missingEmailMessage",
    };
  } else if (!validationResult.isEmailVerified) {
    return {
      title: "UserValidation.emailNotVerifiedTitle",
      message: "UserValidation.emailNotVerifiedMessage",
    };
  } else {
    return {
      title: "Ok",
      message: "Ok",
    };
  }
}
