import { useCallback, useEffect, useMemo, useState } from "react";
import { array, boolean, eq, nonEmptyArray, option, set } from "fp-ts";
import * as zipper from "fp-ts-contrib/Zipper";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { constant, pipe } from "fp-ts/function";
import * as classes from "./FlowAccordion.treat";
import {
  Box,
  CheckIcon,
  ContentRow,
  LocalizedString,
  MacroStepper,
  Panel,
  PanelHeaderIcon,
  PencilIcon,
  Stack,
  Sticky,
  useIsMobileLayout,
} from "design-system";
import { spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  value: Option<number>;
  items: NonEmptyArray<FlowAccordionItem>;
  onChange: (value: Option<number>) => unknown;
  disableNavigation?: boolean;
  contentRowType?: "lateral-margins" | "full";
};

type PanelProps = React.ComponentProps<typeof Panel>;

type FlowAccordionContentProps = {
  down: () => unknown;
  up: (resetNextSteps?: boolean) => unknown;
  move: (index: number, resetNextSteps?: boolean) => unknown;
  resetNextSteps: () => unknown;
};

export type FlowAccordionItem = {
  /**
   * Title of the Panel
   */
  title: LocalizedString;
  /**
   * Content of the Panel, to be rendered only when open
   */
  content: (contentProps: FlowAccordionContentProps) => JSX.Element | null;
  /**
   * Alternative key for accordion panels
   */
  key?: React.Key | null;
  disabled?: boolean;
  hideTitle?: boolean;
};

function panelIcon(
  panelStatus: PanelProps["status"],
  stepNumber: number,
  disabled?: boolean
): JSX.Element {
  switch (panelStatus) {
    case "complete":
      return disabled ? (
        <PanelHeaderIcon variant="disabled" type="icon" icon={CheckIcon} />
      ) : (
        <PanelHeaderIcon variant="complete" type="icon" icon={CheckIcon} />
      );
    case "active":
    case "static":
      return disabled ? (
        <PanelHeaderIcon variant="disabled" type="number" value={stepNumber} />
      ) : (
        <PanelHeaderIcon variant="active" type="number" value={stepNumber} />
      );
    case "hanging":
      return disabled ? (
        <PanelHeaderIcon variant="disabled" type="icon" icon={PencilIcon} />
      ) : (
        <PanelHeaderIcon variant="interactive" type="icon" icon={PencilIcon} />
      );
    case "disabled":
      return (
        <PanelHeaderIcon variant="disabled" type="number" value={stepNumber} />
      );
  }
}

function getStatusFromIndex(
  accordionItemIndex: number,
  currentIndex: Option<number>,
  visitedPanels: Set<number>
): PanelProps["status"] {
  return pipe(
    currentIndex,
    option.fold(constant("disabled"), currentIndex => {
      if (accordionItemIndex < currentIndex) {
        return "complete";
      }
      if (accordionItemIndex === currentIndex) {
        return "active";
      }
      if (visitedPanels.has(accordionItemIndex)) {
        return "hanging";
      }
      return "disabled";
    })
  );
}

export function FlowAccordionMacro(props: Props) {
  const { items, onChange } = props;
  const isMobileLayout = useIsMobileLayout();

  const [visitedPanels, setVisitedPanels] = useState<Set<number>>(new Set());

  const changeIndex = useCallback(
    (index: number, resetNextSteps?: boolean) => {
      if (resetNextSteps) {
        setVisitedPanels(set.filter(i => i < index));
      } else {
        setVisitedPanels(set.insert(eq.eqNumber)(index));
      }

      return onChange(option.some(index));
    },
    [onChange]
  );

  useEffect(() => {
    pipe(
      props.value,
      option.map(i =>
        setVisitedPanels(oldPanels =>
          pipe(
            array.range(0, i),
            set.fromArray(eq.eqNumber),
            set.union(eq.eqNumber)(oldPanels)
          )
        )
      )
    );
  }, [props.value]);

  const onStepSelect = useCallback(
    (index: number) => {
      const status = props.items[index].disabled
        ? "disabled"
        : getStatusFromIndex(index, props.value, visitedPanels);

      const isOpen = status === "active";

      return pipe(
        status !== "disabled" && !isOpen,
        boolean.fold(
          () => option.none,
          () => option.some(() => changeIndex(index, false))
        )
      );
    },
    [changeIndex, props.items, props.value, visitedPanels]
  );

  const panels = useMemo(
    () =>
      items.map((accordionItem, index) => {
        const status = getStatusFromIndex(index, props.value, visitedPanels);

        const isOpen = status === "active";

        const panelKey = pipe(
          !!accordionItem.key || accordionItem.key === 0,
          boolean.fold(
            () => accordionItem.title,
            () => accordionItem.key
          )
        );

        return (
          <Panel
            className={classes.noPadding}
            key={panelKey}
            status={
              (props.disableNavigation && status !== "active") ||
              accordionItem.disabled
                ? "disabled"
                : status
            }
            title={accordionItem.title}
            hideTitle={accordionItem.hideTitle}
            content={() =>
              accordionItem.content({
                up: resetNextSteps => changeIndex(index - 1, resetNextSteps),
                down: () => changeIndex(index + 1, false),
                move: (index, resetNextSteps = false) =>
                  changeIndex(index, resetNextSteps),
                resetNextSteps: () =>
                  setVisitedPanels(set.filter(i => i < index)),
              })
            }
            headerIcon={pipe(
              isMobileLayout,
              boolean.fold(
                () =>
                  option.some(
                    panelIcon(status, index + 1, accordionItem.disabled)
                  ),
                () => option.none
              )
            )}
            isOpen={isOpen}
            onSelect={onStepSelect(index)}
            data-test-id={
              status === "active" ? "accordion_panel_active" : undefined
            }
            stripSurroundingPadding={true}
          />
        );
      }),
    [
      items,
      changeIndex,
      visitedPanels,
      isMobileLayout,
      props.disableNavigation,
      props.value,
      onStepSelect,
    ]
  );

  return pipe(
    isMobileLayout,
    boolean.fold(
      () => (
        <ContentRow type={props.contentRowType || "lateral-margins"}>
          <Stack units={4} column grow shrink>
            {panels}
          </Stack>
        </ContentRow>
      ),
      () => {
        const currentIndex = pipe(
          props.value,
          // on mobile we always have one visible panel, default to 0 in case it's none for some reason
          option.getOrElse(constant(0))
        );
        const steps = pipe(
          items,
          nonEmptyArray.map(item => item.title),
          zipper.fromNonEmptyArray,
          z =>
            pipe(
              zipper.move(constant(currentIndex), z),
              option.getOrElse(constant(z))
            )
        );

        return (
          <Box grow shrink column>
            <Sticky
              className={classes.stickyStepper}
              top={0}
              offsetTopToStuck={10 * spaceUnit}
              classWhenStuck={classes.stickyStepperTop}
            >
              <MacroStepper steps={steps} onStepClick={onStepSelect} />
            </Sticky>
            {pipe(panels, array.lookup(currentIndex), option.toNullable)}
          </Box>
        );
      }
    )
  );
}
