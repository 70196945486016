import { ErrorBanner, useAuthenticationContext } from "design-system";
import { pipe } from "fp-ts/function";
import { useMemo } from "react";
import { useFormatMessage } from "../intl";
import * as remoteData from "../RemoteData";
import { useRemoteData } from "../useRemoteData";
import { BasicFullScreenLoader } from "./BasicFullScreenLoader";
import { PortalStatusWrapper } from "./PortalStatusAlert/PortalStatusWrapper";
import { VersionChecker } from "../VersionCheck/VersionChecker";

type Props = {
  render: () => JSX.Element;
  keepClient?: boolean;
};

export function StartNewFlow(props: Props) {
  return (
    <VersionChecker>
      <StartNewFlow_ {...props} />
    </VersionChecker>
  );
}

function StartNewFlow_(props: Props) {
  const { startNewFlow, startNewFlowKeepClient } = useAuthenticationContext();
  const startRD = useRemoteData(
    useMemo(
      () => (props.keepClient ? startNewFlowKeepClient : startNewFlow),
      []
    )
  );
  const formatMessage = useFormatMessage();
  return pipe(
    startRD,
    remoteData.fold(
      () => <BasicFullScreenLoader />,
      () => (
        <PortalStatusWrapper>
          <ErrorBanner children={formatMessage("GenericError")} />
        </PortalStatusWrapper>
      ),
      props.render
    )
  );
}
