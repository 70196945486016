import * as t from "io-ts";
import { apiCall } from "../../APICall";
import { constFalse, constTrue } from "fp-ts/function";
import { eq } from "fp-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";

export const ValidateDetailsOutput = t.type(
  {
    hasPasswordForCommunication: t.boolean,
    hasPhoneNumber: t.boolean,
    hasEmailAddress: t.boolean,
    isEmailVerified: t.boolean,
    isPhoneNumberVerified: t.boolean,
  },
  "ValidateDetailsOutput"
);

export const CheckStatus = t.keyof({
  PENDING: true,
  DONE: true,
  ERROR: true,
});

export const results = apiCall({
  inputEq: eq.fromEquals(constFalse),
  inputCodec: t.void,
  path: ["clients", "personalProfile", "results"],
  outputCodec: t.type({
    id: optionFromNullable(t.string),
    status: CheckStatus,
  }),
});

export type ValidateDetailsOutput = t.TypeOf<typeof ValidateDetailsOutput>;

export const validateDetails = apiCall({
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "identification", "validateDetails"],
  outputCodec: ValidateDetailsOutput,
});
