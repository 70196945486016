import { ComponentProps } from "react";
import { ReadOnlyField } from "design-system";
import { useFormatMoneyAmountValue } from "../../intl";
import { constant, pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { MoneyField } from "../Price/MoneyField/MoneyField";

type Feedback = ComponentProps<typeof ReadOnlyField>["feedback"];

type Props = ComponentProps<typeof MoneyField> & {
  edit: boolean;
  feedback?: Feedback;
};

export function OptionalEditMoneyField(props: Props) {
  const formatMoneyAmountValue = useFormatMoneyAmountValue();
  const { edit, ...fieldProps } = props;

  return edit ? (
    <MoneyField {...fieldProps} />
  ) : (
    <ReadOnlyField
      size="medium"
      label={props.label}
      value={formatMoneyAmountValue(
        pipe(props.value, option.getOrElse(constant(0)), amount => ({
          amount,
          currency: props.currency,
        }))
      )}
      feedback={props.feedback}
    />
  );
}
