import { useCommand } from "../../useAPI";
import { FilePDFDialog } from "../Dialogs/FilePDFDialog/FilePDFDialog";
import * as documentAPI from "../documentAPI";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useFormatMessage } from "../../intl";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { mediationInsuranceDocset } from "./utils";
import { useState } from "react";
import { Option } from "fp-ts/Option";
import { ForeignDocumentDialog } from "../../UKontoSecondPart/Foreign/ForeignDocumentDialog";
import { LocalizedString } from "design-system";

type Props = {
  document: documentAPI.DocumentMeta;
  handleDismiss: () => unknown;
  handleConfirm?: (id: NonEmptyString) => unknown;
  sealed: boolean;
  mustReadAllDocuments?: boolean;
  isForeignClient?: boolean;
  subtitle?: LocalizedString;
};

export function DocumentDialog(props: Props) {
  const { document, sealed, handleDismiss, handleConfirm } = props;
  const formatMessage = useFormatMessage();

  const [showForeignContractModal, setShowForeignContractModal] = useState<
    Option<documentAPI.DocumentMeta>
  >(option.none);

  const documentDownload = useCommand(
    sealed ? documentAPI.contentSealed : documentAPI.content
  );

  const onHandleConfirm = pipe(
    handleConfirm,
    option.fromNullable,
    option.map(handleConfirm => {
      return () => {
        handleConfirm(document.docId);
        handleDismiss();
      };
    })
  );

  const isModalInsuranceMediation = pipe(
    props.document.docset,
    option.exists(item => item === mediationInsuranceDocset)
  );

  const onForeignDocumentOpen = (document: documentAPI.DocumentMeta) => {
    setShowForeignContractModal(option.some(document));
  };

  return pipe(
    showForeignContractModal,
    option.fold(
      () => (
        <FilePDFDialog
          title={document.docTitle}
          subtitle={props.subtitle}
          onDismiss={handleDismiss}
          onConfirm={pipe(
            onHandleConfirm,
            option.map(action => ({
              action,
              label: isModalInsuranceMediation
                ? formatMessage(
                    "UKonto.ReadDocuments.Modal.confirm.uKontoInsuranceMediation"
                  )
                : formatMessage("UKonto.ReadDocuments.Modal.confirm"),
            })),
            option.toUndefined
          )}
          file={documentDownload({
            docId: document.docId,
          })}
          infoBannerContent={
            isModalInsuranceMediation
              ? formatMessage(
                  "UKonto.ReadDocuments.Modal.description.uKontoInsuranceMediation"
                )
              : undefined
          }
          mustReadAllDocuments={props.mustReadAllDocuments}
          isForeignClient={props.isForeignClient}
          onForeignDocumentOpen={onForeignDocumentOpen}
          document={props.document}
        />
      ),
      document => (
        <ForeignDocumentDialog
          onDismiss={() => setShowForeignContractModal(option.none)}
          title={document.docTitle}
          contractType={pipe(
            document.docType,
            option.fold(
              () => "PRODUCT_CONTRACT",
              docType => {
                switch (docType) {
                  case "FRAMEWORK_AGREEMENT":
                    return "FRAMEWORK_AGREEMENT";
                  case "PRODUCT_CONTRACT_ACCOUNT":
                    return "PRODUCT_CONTRACT";
                  default:
                    return "PRODUCT_CONTRACT";
                }
              }
            )
          )}
        />
      )
    )
  );
}
