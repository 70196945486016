import { Loader, Box, LocalizedString } from "design-system";
import { boolean, option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { useEffect, useState } from "react";

import { foldPortalStatus } from "../../globalDomain";
import { useFormatMessage } from "../../intl";
import { usePortalStatusContext } from "../../PortalStatusContext";
import PortalStatusMaintenance from "./PortalStatusMaintenance";

type Props = {
  children: JSX.Element;
  redirectWrapper?: boolean;
};

export function PortalStatusWrapper(props: Props) {
  const { portalStatus } = usePortalStatusContext();
  const formatMessage = useFormatMessage();

  const [initialPortalStatus, setInitialPortalStatus] = useState(portalStatus);

  useEffect(() => {
    if (initialPortalStatus.type === "initial") {
      setInitialPortalStatus(portalStatus);
    }
  }, [portalStatus]);

  return (
    <>
      {foldPortalStatus(
        props.redirectWrapper ? initialPortalStatus : portalStatus,
        {
          onInitial: () => (
            <Box hAlignContent="center">
              <Loader />
            </Box>
          ),
          onNone: () => <>{props.children}</>,
          onActive: (message: LocalizedString) => (
            <PortalStatusMaintenance
              message={message}
              additionalMessage={pipe(
                !!props.redirectWrapper,
                boolean.fold(
                  () => option.none,
                  () =>
                    option.some(
                      formatMessage(
                        "ClientRedirect.closePageAfterPortalUnblockMessage"
                      )
                    )
                )
              )}
            />
          ),
          onScheduled: () => <>{props.children}</>,
        }
      )}
    </>
  );
}
