import { constant, pipe } from "fp-ts/function";
import {
  TextChildren,
  ReadOnlyField,
  InlineMessage,
  LocalizedString,
} from "design-system";

import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatDate } from "../../intl";
import { ComponentProps } from "react";

type Props = {
  id?: string;
  value: Option<Date>;
  label: TextChildren;
  format: "mm-yyyy" | "dd-mm-yyyy";
  feedback?: {
    type: ComponentProps<typeof InlineMessage>["type"];
    message: LocalizedString;
  };
};

export function ReadOnlyDateField(props: Props) {
  const formatDate = useFormatDate(props.format);
  return (
    <ReadOnlyField
      {...props}
      size="medium"
      value={pipe(props.value, option.fold(constant("-"), formatDate))}
    />
  );
}
