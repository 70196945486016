import { ContentRow, FeedbackBlock } from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { option } from "fp-ts";
import { constVoid } from "fp-ts/function";
import { useFormatMessage } from "../intl";
import { useAppContext } from "../useAppContext";
import { MainLayout } from "./MainLayout/MainLayout";

export function PageNotFound() {
  const formatMessage = useFormatMessage();
  const { config } = useAppContext();
  return (
    <MainLayout
      title={option.none}
      backgroundImageURL={option.none}
      onLogoClick={option.none}
      iconAction={option.none}
      onStepClick={constVoid}
      steps={option.none}
      contentBackground={palette.background}
    >
      <ContentRow type="lateral-margins">
        <FeedbackBlock
          type="warning"
          size="large"
          heading={formatMessage("PageNotFound.heading")}
          subheading={option.none}
          actions={[
            {
              variant: "primary",
              label: formatMessage("PageNotFound.actionLabel"),
              action: () => (window.location.href = config.PWSURL),
            },
          ]}
        />
      </ContentRow>
    </MainLayout>
  );
}
