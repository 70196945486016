import { useState } from "react";
import {
  Banner,
  Box,
  Button,
  buttonLink,
  Checkbox,
  ContentRow,
  ErrorBanner,
  Loader,
  Space,
  Stack,
  useIsMobileLayout,
  LocalizedString,
} from "design-system";
import { Contracts, ReadingConfirmProps } from "./Contracts";
import { option, set, nonEmptyArray, eq } from "fp-ts";
import { pipe, constant, constNull, flow, constTrue } from "fp-ts/function";

import * as remoteData from "../../RemoteData";
import {
  reducerConfig,
  startSignatureAction,
  dismissMarketingModalAction,
} from "./state";
import { MarketingConsentConfirmationDialog } from "./MarketingConsentConfirmationDialog";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { MarketingConsentDialog } from "./MarketingConsentDialog";
import { ReaderTaskEither } from "fp-ts/ReaderTaskEither";
import { TaskEither } from "fp-ts/TaskEither";
import { useRemoteData } from "../../useRemoteData";
import { Option } from "fp-ts/Option";
import * as api from "./api";
import { useParentSharedReducer } from "../../BranchExperience/useSharedReducer";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";
import { DocumentsSignature, SignatureProps } from "./DocumentsSignature";
import {
  getPreContractualDocumentsActions,
  getSmartKeyInitialMode,
  useFormatError,
} from "./utils";
import { useFormatMessage } from "../../intl";
import { TermsAndConditions } from "./TermsAndConditions";
import { CheckingRemoteClientSignature } from "../../StandardLoan/FollowUpAndSignature/LowRiskClient/CheckingRemoteClientSignature";
import { LFStatus } from "../../StandardLoan/api";
import { usePortalStatusContext } from "../../PortalStatusContext";

interface Props {
  onContinue: () => unknown;
  onContinueRemoteSigning: (status: Option<LFStatus>) => unknown;
  onReadContract: ReaderTaskEither<NonEmptyString, unknown, unknown>;
  onUnreadContract: ReaderTaskEither<NonEmptyString, unknown, unknown>;
  onTermsAndConditionsReceived: TaskEither<unknown, unknown>;
  onMarketingConsentExpressed: ReaderTaskEither<boolean, unknown, unknown>;
  getDocuments: TaskEither<unknown, api.PreContractualDocumentsListOutput>;
  onBack: () => unknown;
  getTermsAndConditions: Option<
    TaskEither<unknown, api.TermsAndConditionsOutput>
  >;
  showReadingConfirm: boolean;
  showMarketingConsent: boolean;
  showContractsAgreement: boolean;
  inlineReadingCheckbox: boolean;
  promotion: Option<LocalizedString>;
  signatureProps: SignatureProps;
  hasInsurance?: boolean;
  mustReadAllDocuments?: boolean;
  skipContracts: boolean;
  showBackButton: boolean;
  hideDocumentsSignatureBox?: boolean;
  startCheckingRemoteSignature?: boolean;
  shouldVerifySignatureStatus?: boolean;
  onAfterSignature?: () => unknown;
  isForeignClient?: boolean;
  onForeignSigningConsentExpressed?: ReaderTaskEither<
    boolean,
    unknown,
    unknown
  >;
  variant?: string;
}

export function PreContractualDocuments(props: Props) {
  const formatMessage = useFormatMessage();
  const formatError = useFormatError();
  const isMobileLayout = useIsMobileLayout();
  const { portalBlocked } = usePortalStatusContext();

  const [state, dispatch] = useParentSharedReducer(reducerConfig, {
    type: "ReadDocuments",
    documentsRead: set.empty,
    documentsReceivedConfirmation: !props.showContractsAgreement,
    marketingConsent: false,
    isMarketingConsentModalOpen: false,
    error: option.none,
    authenticationMethod: option.some(
      props.signatureProps.authenticationMethod
    ),
    foreignSigningConsent: false,
  });

  const [marketingModalOpen, setMarketingModalOpen] = useState(false);
  const preContractualDocuments = useRemoteData(props.getDocuments);
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();

  const {
    handleContractRead,
    handleContractUnread,
    handlePreContractualReceived,
    handleMarketingConsentAcceptance,
    handleModalMarketingConsentAcceptance,
    handleForeignSigningConsentAcceptance,
  } = getPreContractualDocumentsActions(dispatch, {
    contractRead: props.onReadContract,
    contractUnread: props.onUnreadContract,
    termsAndConditionsReceived: props.onTermsAndConditionsReceived,
    marketingConsent: props.onMarketingConsentExpressed,
    foreignSigningConsent: props.onForeignSigningConsentExpressed,
  });

  return pipe(
    preContractualDocuments,
    remoteData.fold(
      () => (
        <Box width="100%" hAlignContent="center">
          <Loader />
        </Box>
      ),
      constNull,
      flow(
        nonEmptyArray.filter(({ contractualDocument }) =>
          pipe(contractualDocument, option.getOrElse(constTrue))
        ),
        option.fold(constNull, documents => {
          const documentsRead =
            state.type === "ReadDocuments" && props.showReadingConfirm
              ? state.documentsRead
              : pipe(
                  documents,
                  nonEmptyArray.map(document => document.docId),
                  set.fromArray<NonEmptyString>(eq.eqString)
                );

          const contractsReadingConfirmationProps: ReadingConfirmProps = props.showReadingConfirm
            ? branchExperienceFeaturesActive
              ? {
                  readingConfirmationType: "status",
                  documentsRead,
                }
              : {
                  readingConfirmationType: "checkbox",
                  documentsRead,
                  onDocumentRead: handleContractRead,
                  onDocumentUnread: handleContractUnread,
                }
            : { readingConfirmationType: "none" };

          const ContentWrapper = (props: {
            children: React.ReactElement<unknown>;
          }) =>
            isMobileLayout ? (
              <ContentRow type="lateral-margins">{props.children}</ContentRow>
            ) : (
              props.children
            );

          const marketingConsentCheckbox = (
            <Box shrink>
              <ContentWrapper>
                <Checkbox
                  name="marketing-consent"
                  multiline
                  label={option.some([
                    formatMessage("UKonto.ReadDocuments.marketingConsent"),
                    buttonLink(
                      () => setMarketingModalOpen(true),
                      formatMessage(
                        "UKonto.ReadDocuments.marketingConsentMoreInfo"
                      )
                    ),
                  ])}
                  value={state.marketingConsent}
                  onChange={event =>
                    handleMarketingConsentAcceptance(
                      event.currentTarget.checked
                    )()
                  }
                  issuesType={option.none}
                  disabled={
                    state.type === "SigningDocuments" ||
                    branchExperienceFeaturesActive ||
                    portalBlocked
                  }
                />
              </ContentWrapper>
            </Box>
          );

          const documentsSignatureBox = (
            <DocumentsSignature
              startSignature={() =>
                dispatch(
                  startSignatureAction(
                    documents,
                    props.showReadingConfirm,
                    props.showMarketingConsent,
                    props.isForeignClient ? props.isForeignClient : false
                  )
                )
              }
              onContinue={
                props.shouldVerifySignatureStatus &&
                props.onAfterSignature !== undefined
                  ? props.onAfterSignature
                  : props.onContinue
              }
              isSignatureStarted={state.type === "SigningDocuments"}
              signatureProps={props.signatureProps}
              smartKeyInitialMode={pipe(
                getSmartKeyInitialMode(state.authenticationMethod),
                option.toUndefined
              )}
              onCancelSignature={() => dispatch({ type: "CancelSignature" })}
              otpLength={props.skipContracts ? 6 : undefined}
              hideRequestNotice={props.skipContracts}
            />
          );

          const triggerRemoteFinalPage = (
            <CheckingRemoteClientSignature
              onContinueRemoteSigning={props.onContinueRemoteSigning}
              onCancelSignature={() => dispatch({ type: "CancelSignature" })}
            />
          );

          const contractsAgreementCheckbox = (
            <Box shrink>
              <ContentWrapper>
                <Checkbox
                  name="contracts-agreement"
                  multiline
                  label={option.some(
                    formatMessage("UKonto.ReadDocuments.contractsAgreement")
                  )}
                  value={
                    state.type !== "ReadDocuments" ||
                    state.documentsReceivedConfirmation
                  }
                  onChange={event =>
                    handlePreContractualReceived(event.currentTarget.checked)()
                  }
                  issuesType={
                    state.type === "ReadDocuments"
                      ? pipe(
                          state.error,
                          option.filter(
                            constant(!state.documentsReceivedConfirmation)
                          ),
                          option.map(constant("error" as const))
                        )
                      : option.none
                  }
                  disabled={
                    state.type === "SigningDocuments" ||
                    branchExperienceFeaturesActive ||
                    portalBlocked
                  }
                />
              </ContentWrapper>
            </Box>
          );

          const foreignSigningConsentCheckbox = (
            <Box shrink>
              <ContentWrapper>
                <Checkbox
                  name="foreign-consent"
                  multiline
                  label={option.some(
                    formatMessage("UKonto.ReadDocuments.foreignSigningConsent")
                  )}
                  value={
                    state.type !== "ReadDocuments" ||
                    state.foreignSigningConsent
                  }
                  onChange={event =>
                    handleForeignSigningConsentAcceptance(
                      event.currentTarget.checked
                    )()
                  }
                  issuesType={
                    state.type === "ReadDocuments"
                      ? pipe(
                          state.error,
                          option.filter(constant(!state.foreignSigningConsent)),
                          option.map(constant("error" as const))
                        )
                      : option.none
                  }
                  disabled={
                    state.type === "SigningDocuments" ||
                    branchExperienceFeaturesActive ||
                    portalBlocked
                  }
                />
              </ContentWrapper>
            </Box>
          );

          return (
            <Box column shrink>
              {!props.skipContracts && (
                <Contracts
                  {...contractsReadingConfirmationProps}
                  hasInsurance={props.hasInsurance}
                  documents={documents}
                  showErrors={
                    state.type === "ReadDocuments" && option.isSome(state.error)
                  }
                  mustReadAllDocuments={props.mustReadAllDocuments}
                  isSigningStarted={state.type === "SigningDocuments"}
                  variant={props.variant}
                  isForeignClient={props.isForeignClient}
                />
              )}
              {pipe(
                props.promotion,
                option.map(promotion => (
                  <>
                    <Space units={12} />
                    <Banner
                      type="informative"
                      title={option.some(
                        formatMessage("CreateProfile.promotionTitle")
                      )}
                      content={promotion}
                      actions={option.none}
                      onDismiss={option.none}
                    />
                  </>
                )),
                option.toNullable
              )}
              {!props.skipContracts &&
                pipe(
                  props.getTermsAndConditions,
                  option.map(getTermsAndConditions => (
                    <>
                      <Space units={12} />
                      <TermsAndConditions
                        getTermsAndConditions={getTermsAndConditions}
                        variant={props.variant}
                      />
                    </>
                  )),
                  option.toNullable
                )}
              <Space units={12} />
              <Stack column units={2}>
                {props.showContractsAgreement && contractsAgreementCheckbox}
                {props.showMarketingConsent && marketingConsentCheckbox}
                {props.isForeignClient && foreignSigningConsentCheckbox}
              </Stack>
              {state.type === "ReadDocuments"
                ? pipe(
                    state.error,
                    option.fold(constNull, error => (
                      <>
                        <Space units={4} />
                        <ErrorBanner>{formatError(error)}</ErrorBanner>
                      </>
                    ))
                  )
                : null}
              {props.signatureProps.authenticationMethod === "PIN" &&
              props.showBackButton ? (
                <>
                  <Space units={6} />
                  <Button
                    variant="text"
                    size="default"
                    label={formatMessage("Back")}
                    action={props.onBack}
                    disabled={
                      state.type === "SigningDocuments" || portalBlocked
                    }
                  />
                </>
              ) : (
                <Space units={12} />
              )}
              {!props.hideDocumentsSignatureBox &&
                props.skipContracts &&
                props.signatureProps.authenticationMethod === "PIN" && (
                  <Banner
                    type="informative"
                    content={formatMessage("SB.AuthorizeChanges.info.label")}
                    title={option.some(
                      formatMessage("SB.AuthorizeChanges.info.title")
                    )}
                    actions={option.none}
                    onDismiss={option.none}
                  />
                )}
              {!props.hideDocumentsSignatureBox && documentsSignatureBox}
              {props.startCheckingRemoteSignature && triggerRemoteFinalPage}
              {state.type === "ReadDocuments" &&
                state.isMarketingConsentModalOpen &&
                !branchExperienceFeaturesActive && (
                  <MarketingConsentConfirmationDialog
                    onAnswer={consent =>
                      handleModalMarketingConsentAcceptance(consent)()
                    }
                    onDismiss={flow(dismissMarketingModalAction, dispatch)}
                  />
                )}
              {marketingModalOpen && (
                <MarketingConsentDialog
                  onDismiss={() => setMarketingModalOpen(false)}
                />
              )}
            </Box>
          );
        })
      )
    )
  );
}
