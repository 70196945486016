import { LoadingButton, LocalizedString } from "design-system";

import { task, taskEither } from "fp-ts";
import { constVoid, pipe } from "fp-ts/function";
import { TaskEither } from "fp-ts/TaskEither";
import { ComponentProps, useEffect, useState } from "react";
import { formatDuration, useFormatMessage } from "../../intl";
import { useAppContext } from "../../useAppContext";
import { useBranchExperienceContext } from "../../BranchExperience/BranchExperienceContext";

export type BranchExperienceState = "sharedWithClient" | "notShared";

export type Props = {
  branchExperienceState: BranchExperienceState;
  action: TaskEither<unknown, unknown>;
  submitLabel: LocalizedString;
  disabled?: boolean;
  shareWithClientLabel?: LocalizedString;
};

type State =
  | { type: "readyToShare" }
  | { type: "sharingWithCountdown"; startTime: number }
  | { type: "confirmedByClient" };

const alwaysConfirmed: State = { type: "confirmedByClient" };
const backToReadyOrSharing: State = { type: "readyToShare" };
const countdownIntervalMs = 200;

export function useShareWithClientButtonProps(
  props: Props
): Pick<
  ComponentProps<typeof LoadingButton>,
  "action" | "labels" | "loadingMessage" | "disabled"
> {
  const formatMessage = useFormatMessage();
  const { branchExperienceFeaturesActive } = useBranchExperienceContext();
  const {
    config: {
      shareWithClientButtonConfirmationDelayMs,
      shareWithClientButtonShareDelayMs,
    },
  } = useAppContext();

  const [_state, setState] = useState<State>({ type: "readyToShare" });
  const state = branchExperienceFeaturesActive ? _state : alwaysConfirmed;
  useEffect(() => {
    if (props.branchExperienceState !== "sharedWithClient") {
      setState(backToReadyOrSharing);
    } else if (state.type === "sharingWithCountdown") {
      if (
        Date.now() - state.startTime >=
        shareWithClientButtonConfirmationDelayMs
      ) {
        setState({ type: "confirmedByClient" });
      } else {
        const timeout = setTimeout(() => {
          if (
            state.type === "sharingWithCountdown" &&
            props.branchExperienceState === "sharedWithClient"
          ) {
            setState({
              type: "sharingWithCountdown",
              startTime: state.startTime,
            });
          }
        }, countdownIntervalMs);
        return () => clearTimeout(timeout);
      }
    }
    return constVoid;
  }, [props.branchExperienceState, state]);

  const action = ((): TaskEither<unknown, unknown> => {
    switch (state.type) {
      case "readyToShare":
        return pipe(
          props.action,
          taskEither.chain(() =>
            taskEither.rightTask(
              pipe(task.of(null), task.delay(shareWithClientButtonShareDelayMs))
            )
          ),
          taskEither.chain(() =>
            taskEither.fromIO(() =>
              setState({ type: "sharingWithCountdown", startTime: Date.now() })
            )
          )
        );
      case "sharingWithCountdown":
        return taskEither.rightIO(constVoid);
      case "confirmedByClient":
        return props.action;
    }
  })();

  const resultLabel = ((): LocalizedString => {
    switch (state.type) {
      case "readyToShare":
      case "sharingWithCountdown":
        return (
          props.shareWithClientLabel ||
          formatMessage("ShareWithClientButton.label")
        );
      case "confirmedByClient":
        return props.submitLabel;
    }
  })();

  const label = ((): LocalizedString => {
    switch (state.type) {
      case "readyToShare":
        return (
          props.shareWithClientLabel ||
          formatMessage("ShareWithClientButton.label")
        );
      case "sharingWithCountdown":
      case "confirmedByClient":
        return props.submitLabel;
    }
  })();

  return {
    labels: {
      normal: label,
      success: resultLabel,
      loading: label,
      error: resultLabel,
    },
    action,
    loadingMessage:
      state.type === "sharingWithCountdown"
        ? formatMessage("ShareWithClientButton.counterMessage", {
            seconds: formatDuration(
              Math.round(
                (shareWithClientButtonConfirmationDelayMs -
                  (Date.now() - state.startTime)) /
                  1000
              )
            ),
          })
        : undefined,
    disabled: state.type === "sharingWithCountdown" || props.disabled,
  };
}
