import {
  Body,
  Box,
  Checkbox,
  Dialog,
  LoadingButton,
  Space,
  WarningIcon,
} from "design-system";
import { boolean, option, taskEither } from "fp-ts";
import { useFormatMessage, useFormatRichMessage2 } from "../intl";
import { ComponentProps, useEffect, useState } from "react";
import { FranchiseBranchGdprData } from "./api";
import { pipe } from "fp-ts/function";
import { useCommand } from "../useAPI";
import * as gdprApi from "./api";
import { palette, spaceUnit } from "design-system/lib/styleConstants";
import { useAppContext } from "../useAppContext";

type Props = {
  onDismiss: () => void;
  onContinue: () => void;
  paperBased?: boolean;
  onShareWithClient?: (gdprData: FranchiseBranchGdprData) => void;
  accepted?: boolean;
};

export const GDPR = ({
  paperBased = false,
  onShareWithClient,
  accepted,
  ...props
}: Props) => {
  const getGdprData = useCommand(gdprApi.getFranchiseBranchGdprData);
  const setGdprConsent = useCommand(gdprApi.setFranchiseBranchGdprConsent);
  const formatMessage = useFormatMessage();
  const formatRichMessage = useFormatRichMessage2();
  const {
    apiParameters: { channel },
  } = useAppContext();
  const isInPersonChannel = channel.endsWith("InPerson");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [gdprData, setGdprData] = useState<FranchiseBranchGdprData>();

  useEffect(() => {
    pipe(
      getGdprData(),
      taskEither.chain(gdprData =>
        pipe(
          gdprData.franchiseBranchGdprRequired,
          boolean.fold(
            () =>
              taskEither.fromIO(() => {
                props.onContinue();
              }),
            () =>
              taskEither.fromIO(() => {
                setGdprData(gdprData);
                onShareWithClient && onShareWithClient(gdprData);
              })
          )
        )
      )
    )();
  }, []);

  useEffect(() => {
    if (accepted) {
      onContinue();
    }
  }, [accepted]);

  const onContinue = pipe(
    taskEither.fromIO(() => setIsSubmitting(true)),
    taskEither.chain(() => setGdprConsent({ accepted: true, paperBased })),
    taskEither.chain(() => taskEither.fromIO(props.onContinue))
  );

  const onReject = pipe(
    taskEither.fromIO(() => setIsSubmitting(true)),
    taskEither.chain(() => setGdprConsent({ accepted: false, paperBased })),
    taskEither.chain(() => taskEither.fromIO(props.onContinue))
  );

  const [iAgree, setIAgree] = useState(false);
  const actions: Pick<
    ComponentProps<typeof LoadingButton>,
    "variant" | "action" | "labels" | "loadingMessage" | "disabled"
  >[] = onShareWithClient
    ? []
    : isInPersonChannel
    ? [
        {
          variant: "primary",
          disabled: !iAgree,
          labels: {
            normal: formatMessage("Continue"),
            success: formatMessage("Continue"),
            loading: formatMessage("Loading"),
            error: formatMessage("Error"),
          },
          action: onContinue,
        },
      ]
    : [
        {
          variant: "secondary",
          labels: {
            normal: formatMessage("GDPR.remote.action.reject"),
            success: formatMessage("GDPR.remote.action.reject"),
            loading: formatMessage("Loading"),
            error: formatMessage("Error"),
          },
          action: onReject,
        },
        {
          variant: "primary",
          labels: {
            normal: formatMessage("GDPR.remote.action.accept", {
              name: gdprData?.franchiseBranchName,
              city: gdprData?.franchiseBranchCity,
            }),
            success: formatMessage("GDPR.remote.action.accept", {
              name: gdprData?.franchiseBranchName,
              city: gdprData?.franchiseBranchCity,
            }),
            loading: formatMessage("Loading"),
            error: formatMessage("Error"),
          },
          action: onContinue,
        },
      ];

  return (
    <>
      {gdprData?.franchiseBranchGdprRequired && (
        <Dialog
          variant="document"
          onDismiss={option.some(props.onDismiss)}
          actions={[]}
          isSubmitting={isSubmitting}
        >
          <Box column>
            <div
              style={{ overflowY: "auto", maxHeight: "calc(100vh - 300px)" }}
            >
              <Box column hAlignContent="center">
                <div
                  style={{
                    height: "56px",
                    width: "56px",
                    padding: spaceUnit * 2,
                    display: "inline-block",
                    borderRadius: "50%",
                    border: palette.blue800,
                    backgroundColor: palette.blue800,
                  }}
                >
                  <WarningIcon size="x-large" color={palette.white} />
                </div>
                <Space units={4} />
                <Body size={"big"} weight={"medium"}>
                  {formatMessage("GDPR.title")}
                </Body>
              </Box>
              <Space units={8} />
              <div>
                {formatRichMessage(
                  isInPersonChannel
                    ? paperBased
                      ? "GDPR.paperBased.subtitle"
                      : "GDPR.subtitle"
                    : "GDPR.remote.subtitle",
                  {
                    city: gdprData.franchiseBranchCity,
                  },
                  { useWhitespacePreWrap: true }
                )}
              </div>
            </div>
            {isInPersonChannel && !onShareWithClient && (
              <>
                <Space units={4} />
                <Checkbox
                  name={"GDPR.checkbox"}
                  label={option.some(
                    paperBased
                      ? formatMessage("GDPR.paperBased.checkbox.label")
                      : formatMessage("GDPR.checkbox.label")
                  )}
                  onChange={() => setIAgree(prev => !prev)}
                  value={iAgree}
                  issuesType={option.none}
                />
              </>
            )}
            <Space units={4} />
            <Box basis="0%" hAlignContent="right" grow>
              {actions.map(action => (
                <>
                  <Space units={4} />
                  <LoadingButton size="default" {...action} />
                </>
              ))}
            </Box>
            <Space units={2} />
          </Box>
        </Dialog>
      )}
    </>
  );
};
