import { Body, Box, Loader, LoadingButton, Space, Stack } from "design-system";
import { useFormatMessage } from "../intl";
import { useCommand, usePollingEffect } from "../useAPI";
import { constNull, pipe } from "fp-ts/function";
import * as api from "./api";
import { useEffect } from "react";
import { taskEither } from "fp-ts";
import { useDigitalIdParentReducer } from "./state";
import { DigitalIdInstructions } from "./DigitalIdInstructions";

type Props = {
  onContinue: () => unknown;
  onStop: () => unknown;
};

export function DigitalIdStatus({ onContinue, onStop }: Props) {
  const formatMessage = useFormatMessage();
  const [, setState] = useDigitalIdParentReducer();
  const getQrCode = useCommand(api.digitalIdQrCode);

  const getQrCodeAndSave = pipe(
    getQrCode(),
    taskEither.map(qr => setState(qr))
  );

  useEffect(() => {
    getQrCodeAndSave();
    return () => setState(undefined);
  }, []);

  usePollingEffect(api.digitalIdStatus, {
    intervalMS: 2000,
    shouldPollingContinue: ({ status }) => status === "RETRY",
    onError: constNull,
    onSuccess: ({ status }) => {
      if (status === "CONTINUE") {
        onContinue();
      } else if (status === "STOP") {
        onStop();
      }
    },
  });

  return (
    <>
      <Stack units={4} column hAlignContent="center">
        <Loader />
        <Body size="large" weight="bold">
          {formatMessage("DigitalId.Message")}
        </Body>
        <Body size="medium" weight="regular">
          {formatMessage("DigitalId.Description")}
        </Body>
        <Box vAlignContent="center">
          <Body size="medium" weight="medium">
            {formatMessage("DigitalId.Button.Message")}
          </Body>
          <Space units={2} />
          <LoadingButton
            size="default"
            labels={{
              normal: formatMessage("DigitalId.Button.Label"),
              success: formatMessage("DigitalId.Button.Label"),
              loading: formatMessage("DigitalId.Button.Label"),
              error: formatMessage("DigitalId.Button.Label"),
            }}
            variant="text"
            action={getQrCodeAndSave}
          />
        </Box>
      </Stack>
      <DigitalIdInstructions />
    </>
  );
}
