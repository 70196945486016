import { taskEither } from "fp-ts";
import { DigitalIdStart } from "./DigitalIdStart";
import { useState } from "react";
import { DigitalIdClientProfile } from "./DigitalIdClientProfile";
import { DigitalIdStatus } from "./DigitalIdStatus";
import { DigitalIdStop } from "./DigitalIdStop";

type Props = {
  onClientIdentified: () => unknown;
  applicantIndex?: string;
};

type State = "start" | "status" | "stop" | "clientProfile";

export function DigitalId({ onClientIdentified, applicantIndex }: Props) {
  const [state, setState] = useState<State>("start");

  return (
    <>
      {state === "start" && (
        <DigitalIdStart
          onContinue={() => setState("status")}
          applicantIndex={applicantIndex}
        />
      )}
      {state === "status" && (
        <DigitalIdStatus
          onContinue={() => setState("clientProfile")}
          onStop={() => setState("stop")}
        />
      )}
      {state === "stop" && (
        <DigitalIdStop onRetry={taskEither.fromIO(() => setState("start"))} />
      )}
      {state === "clientProfile" && (
        <DigitalIdClientProfile onClientIdentified={onClientIdentified} />
      )}
    </>
  );
}
