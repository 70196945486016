import { MoneyAmount } from "../../globalDomain";
import * as classes from "./DisplayMoneyAmount.treat";
import { useFormatMoneyAmount } from "../../intl";
import { Space, NonNegativeInteger, Box } from "design-system";
import cx from "classnames";

type Props = {
  id?: string;
  inverted?: boolean;
  amount: MoneyAmount;
  size: "input" | "small" | "x-small" | "large" | "big";
  fractionDigits?: NonNegativeInteger;
  vAlignContent?: "top" | "center" | "bottom";
  className?: string;
};

export function DisplayMoneyAmount(props: Props) {
  const formatMoneyAmount = useFormatMoneyAmount(props.fractionDigits);
  return formatMoneyAmount(props.amount, ({ amount, currency }) => (
    <Box
      id={props.id}
      className={cx(
        props.inverted ? classes.light : classes.dark,
        props.className
      )}
      vAlignContent={props.vAlignContent}
    >
      <Box className={classes.amount[props.size]}>{amount}</Box>
      <Space units={1} />
      <Box className={classes.currency[props.size]} vAlignContent="bottom">
        {currency}
      </Box>
    </Box>
  ));
}
