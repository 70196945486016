import {
  Box,
  Heading,
  LocalizedString,
  NumberedList,
  Space,
} from "design-system";
import { palette } from "design-system/lib/styleConstants";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useFormatMessage } from "../intl";

type Props = {};

export function DigitalIdInstructions({}: Props) {
  const formatMessage = useFormatMessage();

  const instructions: NonEmptyArray<LocalizedString> = [
    formatMessage("DigitalId.Instruction.1"),
    formatMessage("DigitalId.Instruction.2"),
    formatMessage("DigitalId.Instruction.3"),
  ];

  return (
    <Box
      column
      width="100%"
      marginTop={20}
      style={{
        backgroundColor: palette.neutral200,
        borderRadius: "5px",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        border: `2px solid ${palette.neutral400}`,
      }}
    >
      <Heading size="x-small" weight="medium">
        {formatMessage("DigitalId.Instruction.Title")}
      </Heading>
      <Space units={2} />
      <NumberedList items={instructions} />
    </Box>
  );
}
