import { RestoreNewestLoanApplicationOutput as SLRestoreData } from "../../StandardLoan/api";

export type RestoreDataType = "latest" | "byId";

export type RestoreDataObjectSL = {
  restoreData: SLRestoreData;
  restoreDataType: RestoreDataType;
};

export function foldRestoreDataType<T>(
  restoreDataType: RestoreDataType,
  match: { onById: () => T; onLatest: () => T }
) {
  switch (restoreDataType) {
    case "byId":
      return match.onById();
    case "latest":
      return match.onLatest();
  }
}
