import { Option } from "fp-ts/Option";
import { pipe } from "fp-ts/function";
import { option, array } from "fp-ts";
import { DropdownOption } from "design-system";

export function selectedDropdownOption<T>(
  initialValue: Option<T>,
  options: Array<DropdownOption<T>>
): Option<DropdownOption<T>> {
  return pipe(
    initialValue,
    option.chain(initialValue =>
      pipe(
        options,
        array.findFirst(o => initialValue === o.value)
      )
    )
  );
}

function isOption<A>(a: Option<A> | A): a is Option<A> {
  return "_tag" in a && a._tag != null;
}

export function dropdownOptionToValue<T>(
  dropdownOption: Option<DropdownOption<T>> | DropdownOption<T>
): Option<T> {
  return pipe(
    isOption(dropdownOption) ? dropdownOption : option.some(dropdownOption),
    option.map(o => o.value)
  );
}
