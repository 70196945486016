import { LocalizedString } from "design-system";
import * as t from "io-ts";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { sharedReducerConfig } from "../../BranchExperience/useSharedReducer";

const Initial = optionFromNullable(
  t.type(
    {
      type: t.keyof({
        informative: true,
        success: true,
        warning: true,
        error: true,
      }),
      message: LocalizedString,
      targetScreen: t.keyof({
        parent: true,
        child: true,
        both: true,
      }),
    },
    "Initial"
  )
);

const State = Initial;
export type State = t.TypeOf<typeof State>;

const SetStateAction = t.type({
  type: t.literal("SetStateAction"),
  state: State,
});
const Action = SetStateAction;
export type Action = t.TypeOf<typeof Action>;

export function setStateAction(state: State): Action {
  return {
    type: "SetStateAction",
    state,
  };
}

function reducer(_: State, action: Action): State {
  switch (action.type) {
    case "SetStateAction":
      return action.state;
  }
}

export const reducerConfig = sharedReducerConfig(
  "SnackbarProvider",
  State,
  Action,
  reducer
);
