import { ComponentProps } from "react";
import { ReadOnlyDateField } from "../ReadOnlyDateField/ReadOnlyDateField";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";
import { MonthYearField } from "../MonthYearField/MonthYearField";

type Feedback = ComponentProps<typeof ReadOnlyDateField>["feedback"];

type Props = ComponentProps<typeof MonthYearField> & {
  edit: boolean;
  feedback?: Feedback;
};

export function OptionalEditMonthYearField(props: Props) {
  const { edit, ...fieldProps } = props;

  const finalDate = pipe(
    props.value,
    option.map(({ month, year }) => {
      const finalDate = new Date();
      finalDate.setFullYear(year);
      finalDate.setMonth(month - 1);
      return finalDate;
    })
  );

  return edit ? (
    <MonthYearField {...fieldProps} />
  ) : (
    <ReadOnlyDateField
      format="mm-yyyy"
      label={props.label}
      value={finalDate}
      feedback={props.feedback}
    />
  );
}
