import * as t from "io-ts";
import { LocalizedString } from "design-system";
import { optionFromNullable } from "io-ts-types/lib/optionFromNullable";
import { apiCall } from "../../APICall";
import { Tenant } from "../../globalDomain";
import { constTrue } from "fp-ts/function";
import { eq } from "fp-ts";

const IDPrefixesOutput = t.array(
  t.type(
    {
      tenant: optionFromNullable(Tenant),
      label: LocalizedString,
      prefix: LocalizedString,
    },
    "Prefix"
  ),
  "Prefixes"
);

export const prefixes = apiCall({
  inputEq: eq.fromEquals(constTrue),
  path: ["clients", "identification", "phoneNumber", "prefixes"],
  inputCodec: t.void,
  outputCodec: IDPrefixesOutput,
});
