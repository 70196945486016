import { LoadingButton } from "design-system";
import {
  Props,
  useShareWithClientButtonProps,
} from "./useShareWithClientButtonProps";

export function ShareWithClientButton(props: Props) {
  const buttonProps = useShareWithClientButtonProps(props);
  return (
    <LoadingButton
      {...buttonProps}
      type="submit"
      variant="primary"
      size="default"
      data-test-id="share_with_client_button"
    />
  );
}
