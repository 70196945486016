import { ComponentProps } from "react";
import { ReadOnlyField } from "design-system";
import { useFormatMessage } from "../../intl";
import { constant, pipe, identity } from "fp-ts/function";
import { option } from "fp-ts";
import { YesNoRadioGroupField } from "../YesNoRadioGroup/YesNoRadioGroupField";

type Feedback = ComponentProps<typeof ReadOnlyField>["feedback"];

type Props = ComponentProps<typeof YesNoRadioGroupField> & {
  edit: boolean;
  feedback?: Feedback;
};

export function OptionalEditYesNoField(props: Props) {
  const formatMessage = useFormatMessage();
  const { edit, ...fieldProps } = props;

  return edit ? (
    <YesNoRadioGroupField {...fieldProps} />
  ) : (
    <ReadOnlyField
      size="medium"
      label={props.label}
      value={pipe(
        props.value,
        option.filter(identity),
        option.fold(
          constant(formatMessage("No")),
          constant(formatMessage("Yes"))
        )
      )}
      feedback={props.feedback}
    />
  );
}
