import { useMemo } from "react";
import { useFormatMessage } from "../../intl";
import { useQuery } from "../../useAPI";
import { useAppContext } from "../../useAppContext";
import * as phoneApi from "./api";
import * as remoteData from "../../RemoteData";
import { constant, constUndefined, constVoid, pipe } from "fp-ts/function";
import { array, option, taskEither } from "fp-ts";
import { CountryFlag } from "../CountryFlag/CountryFlag";
import { useForm, validators } from "design-system";
import { Option } from "fp-ts/Option";
import { useValidators } from "../useValidators";
import { TaskEither } from "fp-ts/TaskEither";
import { NonEmptyString } from "io-ts-types/NonEmptyString";

export function usePhoneNumberFieldConfig() {
  const formatMessage = useFormatMessage();
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const [prefixes] = useQuery(phoneApi.prefixes);
  const prefixOptions = useMemo(
    () =>
      pipe(
        prefixes,
        remoteData.fold(
          constant([]),
          constant([]),
          array.map(prefix => ({
            tenant: prefix.tenant,
            prefix: prefix.prefix,
            label: prefix.label,
            flag: pipe(
              prefix.tenant,
              option.fold(constUndefined, tenant => (
                <CountryFlag tenant={tenant} />
              ))
            ),
          }))
        )
      ),
    [prefixes]
  );

  return {
    prefixOptions,
    tenant,
    loadingMessage: formatMessage("LoadingEllipsis"),
  };
}

export function usePhoneNumberFieldProps(props: {
  initialPhoneNumber: Option<NonEmptyString>;
  onSubmit: (phoneNumer: NonEmptyString) => TaskEither<unknown, unknown>;
}) {
  const phoneNumberFieldConfig = usePhoneNumberFieldConfig();
  const { validPhone, nonBlankString } = useValidators();
  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: {
        phoneNumber: pipe(
          props.initialPhoneNumber,
          option.getOrElse(constant(""))
        ),
      },
      fieldValidators: () => ({
        phoneNumber: validators.inSequence(nonBlankString, validPhone),
      }),
    },
    {
      onSubmit: ({ phoneNumber }) =>
        props.onSubmit
          ? props.onSubmit(phoneNumber)
          : taskEither.fromIO(constVoid),
    }
  );

  return {
    phoneNumberFieldProps: {
      ...phoneNumberFieldConfig,
      ...fieldProps("phoneNumber"),
    },
    handlePhoneNumberSubmit: handleSubmit,
  };
}
