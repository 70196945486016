import { useAuthenticationContext } from "design-system";
import { useEffect } from "react";
import { OpenLoanApplicationForRemoteSigning } from "./OpenLoanApplicationForRemoteSigning";

export const OpenLoanApplicationForRemoteSigningWrapper = () => {
  const { transitionToPotentialClient } = useAuthenticationContext();

  useEffect(() => {
    transitionToPotentialClient();
  }, []);

  return <OpenLoanApplicationForRemoteSigning />;
};
