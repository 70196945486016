import * as t from "io-ts";
import { eq } from "fp-ts";
import { apiCall } from "../APICall";
import { constFalse } from "fp-ts/function";

export const getFranchiseBranchGdprDataOutput = t.type({
  franchiseBranchGdprRequired: t.boolean,
  franchiseBranchName: t.union([t.null, t.undefined, t.string]),
  franchiseBranchCity: t.union([t.null, t.undefined, t.string]),
});

export type FranchiseBranchGdprData = t.TypeOf<
  typeof getFranchiseBranchGdprDataOutput
>;

export const getFranchiseBranchGdprData = apiCall({
  path: ["clients", "franchiseBranchGdpr", "data"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: getFranchiseBranchGdprDataOutput,
});

const setFranchiseBranchGdprConsentInput = t.type({
  accepted: t.boolean,
  paperBased: t.boolean,
});

export const setFranchiseBranchGdprConsent = apiCall({
  path: ["clients", "franchiseBranchGdpr", "consent"],
  inputCodec: setFranchiseBranchGdprConsentInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});
