import * as classes from "./PortalStatusMaintenance.treat";
import {
  Box,
  Space,
  ContentRow,
  SettingsIcon,
  useIsMobileLayout,
  Heading,
  LocalizedString,
  Body,
  TextChildren,
  linebreak,
} from "design-system";
import { option } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../../intl";
import { KOError } from "../KOError/KOError";
import { palette } from "design-system/lib/styleConstants";
import { constVoid, pipe } from "fp-ts/function";
import { MainLayout } from "../MainLayout/MainLayout";

type Props = {
  message: LocalizedString;
  additionalMessage: Option<LocalizedString>;
};

function PortalStatusMaintenance(props: Props) {
  const formatMessage = useFormatMessage();
  const isMobileLayout = useIsMobileLayout();

  if (isMobileLayout) {
    return (
      <Box
        column
        shrink
        className={classes.container}
        style={{ minHeight: "100vh" }}
      >
        <Space units={10} />
        <Box hAlignContent="center">
          <SettingsIcon size="large" />
        </Box>
        <Space units={6} />
        <ContentRow type="lateral-margins">
          <Heading size="medium" weight="medium" align="center">
            {formatMessage("PortalStatus.maintenance.header.title")}
          </Heading>
        </ContentRow>
        <ContentRow type="lateral-margins">
          <Box column shrink>
            <Body size="medium" weight="regular" align="center">
              {props.message}
            </Body>
            {pipe(
              props.additionalMessage,
              option.map(msg => (
                <>
                  <Space units={6} />
                  <Body size="medium" weight="regular" align="center">
                    {msg}
                  </Body>
                </>
              )),
              option.toNullable
            )}
          </Box>
        </ContentRow>
        <Space units={10} />
      </Box>
    );
  }

  return (
    <MainLayout
      title={option.some(formatMessage("PortalStatus.modal.blocked.title"))}
      steps={option.none}
      contentBackground={palette.neutral100}
      backgroundImageURL={option.none}
      onLogoClick={option.none}
      onStepClick={constVoid}
      iconAction={option.none}
    >
      <KOError
        title={formatMessage("PortalStatus.maintenance.header.title")}
        description={pipe(
          props.additionalMessage,
          option.fold<LocalizedString, Option<TextChildren>>(
            () => option.some(props.message),
            msg => option.some([props.message, linebreak, linebreak, msg])
          )
        )}
        action={option.none}
      />
    </MainLayout>
  );
}

export default PortalStatusMaintenance;
