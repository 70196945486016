import { option } from "fp-ts";
import { ComponentProps, forwardRef } from "react";
import { Option } from "fp-ts/Option";
import {
  FieldProps,
  LocalizedString,
  NumberField,
  TextChildren,
} from "design-system";
import { useFormatCurrency } from "../../../intl";
import { Currency, Positive } from "../../../globalDomain";

type Props = FieldProps<Option<number>> & {
  id?: string;
  description?: LocalizedString;
  placeholder: LocalizedString;
  autoFocus?: boolean;
  min?: number;
  max?: number;
  step?: Positive;
  currency: Currency;
  cta?: ComponentProps<typeof NumberField>["cta"];
  assistiveText?: TextChildren;
  decimalDigits?: number;
};

export const MoneyField = forwardRef<HTMLInputElement, Props>(
  (props, forwardedRef) => {
    const formatCurrency = useFormatCurrency();
    const decimalDigits =
      props.decimalDigits !== undefined ? props.decimalDigits : 3;

    return (
      <NumberField
        {...props}
        ref={forwardedRef}
        rightContent={option.some(formatCurrency(props.currency))}
        assistiveText={props.assistiveText}
        formatOptions={{ maximumFractionDigits: decimalDigits }}
      />
    );
  }
);
